import React from 'react';
import NewPresta from '../NewPresta/NewPresta';
import { faShoppingCart, faDesktop, faDatabase, faShield } from '@fortawesome/free-solid-svg-icons';
const PrestaBlock = () => {
    const datas = [
        {
            titre:'Sites web',
            icon:faDesktop,

        },
        {
            titre:'E-commerce',
            icon:faShoppingCart,

        },
        {
            titre:'API REST',
            icon:faShield,

        },


    
    ]

    return (
        <div className='box-container'>
            {
                datas.map( (item, index) => (
                    <div key={index}>
                    {/* <Presta title={item.titre} description={item.description} icone={item.icon} /> */}
                    <NewPresta title={item.titre} description={item.description} icone={item.icon} />
                </div>
                

                ))
            }
      </div>

  );
}

export default PrestaBlock;
