import React from 'react';
import styled, { keyframes } from 'styled-components';
const Neon = ({text, couleurTexte, haloFaible0, haloFaible1, haloFort}) => {

  const blink = keyframes`
    20%,
    24%,
    55% {
      color: ${haloFaible0};
      text-shadow: none;
    }
  
    0%,
    19%,
    21%,
    23%,
    25%,
    54%,
    56%,
    100% {
      text-shadow: 0 0 5px ${haloFort}, 0 0 15px ${haloFort}, 0 0 20px ${haloFort}, 0 0 40px ${haloFort}, 0 0 60px ${haloFaible1}, 0 0 10px #ff8d00, 0 0 98px ${haloFaible1};
      color: ${couleurTexte};
    }
  `;
  
  const Specialneon = styled.h1`
    font-size: 6vh;
    line-height: calc(20px + 20vh);
    text-shadow: 0 0 5px ${haloFort}, 0 0 15px ${haloFort}, 0 0 20px ${haloFort}, 0 0 40px ${haloFort}, 0 0 60px ${haloFaible1}, 0 0 10px #ff8d00, 0 0 98px ${haloFaible1};
    color: ${couleurTexte};
    font-family: "Sacramento", cursive;
    text-align: center;

  `;
  return (
    <Specialneon>{text}</Specialneon>
  );
}

export default Neon;
