import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../src/book_style.css';

const Books = () => {
    const [books, setBooks] = useState([]);

    useEffect(() => {
        fetch('https://luckycheetah.fun/ronify/allbooks')
            .then(response => response.json())
            .then(data => {
                console.log('Books fetched:', data);
                setBooks(data);
            })
            .catch(error => console.error('Error fetching books:', error));
    }, []);

    return (
        <div className="main-content">
            <h1 className='titreRealisations'>Guides de survie
                <small className='titreSmall'>Je partage avec vous mes connaissances sur quelques sujets importants !</small>
            </h1>

            {books.map(book => (
                <div className="moleskine-wrapper" key={book._id}>
                    <Link to={`/guideforgeek/${book._id}`}>
                        <div className="moleskine-notebook">
                            <div className="notebook-cover" style={{ backgroundColor: book.book_color_hexa }}>
                                <div className="notebook-skin">{book.book_mainTitle}</div>
                                <div className="notebook_logo">
                                    <img src={book.book_logoTech} alt={book.book_logoTech_alt} />
                                </div>
                            </div>
                            <div className="notebook-page squared"></div>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default Books;
