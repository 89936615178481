import React, { useState } from 'react';
import Contact from '../Contact/Contact';
import DrawingCanvas from '../Dessiner/Dessiner';
import SwitchButtonMailDraw from '../SwitchButtonMailDraw/SwitchButtonMailDraw';
const ToggleContactCanvas = () => {
  const [mode, setMode] = useState('write'); // État pour suivre le mode actuel : écriture ou dessin

  // Fonction pour basculer entre les modes
  const toggleMode = () => {
    setMode(mode === 'write' ? 'draw' : 'write');
  };

  return (
    <section id='sendMailSection'>
      <div>
      <h1 className='titreRealisations'>Parlez-moi de votre projet
                <small className='titreSmall'>Si vous avez l'âme d'un(e) artiste vous pouvez aussi me laisser un dessin !</small>
            </h1>
        <div>
        <SwitchButtonMailDraw mode={mode} toggleMode={toggleMode} />
        </div>
        {mode === 'write' ? (
          <Contact toggleMode={toggleMode} />
        ) : (
          <div className='transition-all duration-500'>
            <DrawingCanvas toggleMode={toggleMode}/>
          </div>
        )}

      </div>
    </section>
  );
};

export default ToggleContactCanvas;
