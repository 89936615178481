import React from 'react';
import Typing from '../Typing/Typing';
import './Home.css';

const Home = () => {
    const datas = [
        {
            t:"robustes !"
        },
        {
            t: "responsives !"
        },
        {
            t:"qui vous ressemblent !"
        },
        {
            t:"sur mesure !"
        },
        {
            t:"Angular !"
        },
        {
            t:"React !"
        },

    ]


    return (
        <>

{/* <div style={{width:'100%'}}>
<OrbitalAnimation />

</div> */}

        <div className="content">
        


            <div className='contentspan'>
            
                <div className='maintitle'>Développeur web fullstack,</div>
                <div className='typing-container'>
                   <div className='firstPart'>je code des applications &nbsp;</div>
                </div>
                    <Typing datas={datas} speed={50}/>
            </div>
                    
        </div>
        
        </>


  );
}

export default Home;
