import React, { useState, useEffect } from 'react';

const useTypewriter = (datas, speed = 50) => {
    const [displayIndex, setDisplayIndex] = useState(0);
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        let i = 0;
        const typingInterval = setInterval(() => {
            if (i < datas[displayIndex].t.length) {
                setDisplayText(prevText => prevText + datas[displayIndex].t.charAt(i-1));
                i++;
            } else {
                clearInterval(typingInterval);
                setTimeout(() => {
                    setDisplayText('');
                    setDisplayIndex((prevIndex) => (prevIndex + 1) % datas.length);
                }, 1000);
            }
        }, speed);

        return () => {
            clearInterval(typingInterval);
        };
    }, [datas, displayIndex, speed]);

    return displayText;
};

const Typing = ({ datas, speed }) => {
    const displayText = useTypewriter(datas, speed);

    const generateSVGPath = () => {
        const textWidth = displayText.length * 10;
        return `M0 0 Q${textWidth / 2} 10 ${textWidth} 0`;
    };

    return (
        <div className="underline-container">
            <p className="underline-text">{displayText}</p>
            <svg className="underline-svg" height="10" width="100%">
                <path d={generateSVGPath()} stroke="#B38728" fill="transparent" strokeWidth="5" />
            </svg>
        </div>
    );
};

export default Typing;
