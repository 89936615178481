import {React} from 'react';
import { useNavigate } from 'react-router-dom';
import { faGlobe, faLink, faExternalLinkAlt, faArrowRight, faSignOutAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MagicCardUnit = (props) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/projet/${props.id_projet}`);
  };
  
  return (    
    <section className='bg-transparent bg-cover no-repeat'>
      <style>
      {`@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;600&display=swap");


      body section .row {
        align-items: center;
        height: 100vh;


        
      }
        .card_container {
  position: relative; /* Relative pour que la carte enfant puisse s'ajuster à l'intérieur */

  width: 95%;        /* Largeur totale pour correspondre à l'espace de la carte */
  margin:auto;
  display: flex;
  justify-content: center; /* Centre la carte à l'intérieur */
  align-items: center;     /* Centre verticalement la carte */

  @media(max-width:768px){
    overflow: hidden;
    }
}

      .card {
        display:flex;
        cursor:pointer;


    




      

        z-index:500;
        position: relative;
        height: 400px;
        width: 420px;
        margin: 15px;

        
        transition: ease all 2.3s;
        perspective: 1500px;

        @media (max-width: 700px) {
        display:flex;
          width: 350px;
          margin: 20px;
        }

        // @media (min-width: 930px) {
        //   width: 400px;
        //  }

        // @media (min-width: 1200px) {
        //   width: 460px;
        //   }



      // @media (min-width: 1400px) {

      // width:400px;
    
      //     }
      // @media (min-width: 1600px) {

      //       width:350px;
          
      //           }
      // @media (min-width: 1800px) {

      //       width:400px;
                
      // }
      // }

      
      



      .card:hover .cover {
        transform: rotateX(0deg) rotateY(-180deg);
      }
      .card:hover .cover:before {
        transform: translateZ(30px);
      }
      .card:hover .cover:after {
        background-color: black;
      }
      .card:hover .cover h1 {
        transform: translateZ(100px);
      }
      .card:hover .cover .price {
        transform: translateZ(60px);
      }
      .card:hover .cover a {
        transform: translateZ(-60px) rotatey(-180deg);
      }
      .card .cover {
        position: absolute;
        height: 100%;
        width: 100%;
        transform-style: preserve-3d;
        transition: ease all 2.3s;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;



      }

      .card .cover:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
        transition: ease all 1.3s;
        background: rgba(0, 0, 0, 0.4);
      }

      .card .cover h1 {
        font-weight: 600;
        position: absolute;
        bottom: 55px;
        left: 50px;

        transform-style: preserve-3d;
        transition: ease all 2.3s;
        z-index: 3;
        font-size: 3em;
        transform: translateZ(0px);
      }
      .card .cover .price {
        font-weight: 200;
        position: absolute;
        top: 55px;
        right: 50px;
        transform-style: preserve-3d;
        transition: ease all 2.3s;
        z-index: 4;
        font-size: 3em;
        transform: translateZ(0px);

        @media (min-width: 700px) {
          font-size: 4em;
         }
      }
      .card .card-back {
        position: absolute;
        height: 100%;
        width: 100%;
        background: black;
        transform-style: preserve-3d;
        transition: ease all 2.3s;
        transform: translateZ(-1px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding:10px;

      }
      .card .card-back a {
        transform-style: preserve-3d;
        transition: ease transform 2.3s, ease background 0.5s;
        transform: translateZ(-1px) rotatey(-180deg);
        background: transparent;
        border: 1px solid white;
        font-weight: 200;
        font-size: 1.3em;
        color: white;
        padding: 10px 10px;
        outline: none;
        text-decoration: none;
      }
      .card .card-back p{
        position: absolute;
        top:20px;
        padding:30px;
        transform-style: preserve-3d;
        transition: ease transform 2.3s, ease background 0.5s;
        transform: translateZ(-1px) rotatey(-180deg);
        background: transparent;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 1.7em;
        outline: none;

      }

      .card .card-back div{
        position: absolute;
        top:250px;
        padding:30px;
        transform-style: preserve-3d;
        transition: ease transform 2.3s, ease background 0.5s;
        transform: translateZ(-1px) rotatey(-180deg);
        background: transparent;
    
        font-weight: 200;
        font-size: 1.3em;


        outline: none;

      }

      .cover h1{
        font-size: 30px !important;
        @media (min-width: 700px) {
          font-size: 40px;
         }
      }

      .card .belunga1:before{
        border: 5px solid #31C48D;
      }
      .card .belunga2:before{
        border: 5px solid #E02424;
      }
      .card .belunga3:before{
        border: 5px solid #D1D5DB;
      }
      .card .belunga4:before{
        border: 5px solid #FACA15;
      }
      .card .belunga5:before{
        border: 5px solid #3F83F8;
      }
      .card .belunga6:before{
        border: 5px solid #33ffff;
      }
      .card .belunga7:before{
        border: 5px solid #7f00ff;
      }
      .card .belunga8:before{
        border: 5px solid #9C4803;
      }

      
      .color_biog{
        border: 1px solid #3F83F8;
      }
      .color_fedora{
        border: 1px solid #9C4803;
      }
      .color_imhotep{
        border: 1px solid #FACA15;
      }
      .color_catcha{
        border: 1px solid #33ffff;
      }
      .color_zoo{
        border: 1px solid #E02424;
      }
      .color_unit{
        border: 1px solid #D1D5DB;
      }
      .color_vocab{
        border: 1px solid #31C48D;
      }
      .color_psycho{
        border: 1px solid #7f00ff;
      }


      .color_text_vocab{
        color: #31C48D;
      }
      .color_text_fedora{
         color: #9C4803;
      }
      .color_text_imhotep{
        color: #FACA15;
      }
      .color_text_catcha{
        color: #33ffff;
      }
      .color_text_biog{
        color: #D6EAF8;
      }
      .color_text_zoo{
        color: #FADBD8;
      }
      .color_text_unit{
        color: #D1D5DB;
      }
      .color_text_psycho{
        color: #D7BDE2;
      }






      .card .card-back a:hover {
        background-color: white;
        color: #0b0f08;

      }`}
      </style>

        <div className='card_container'>

          <div className="card">



            <div className={`cover item-a ${props.border_projet}`}
            style={{ backgroundImage: `url(${props.image_projet})` }}>
                <style>
                  {`.card .cover:before {
                    content: "";
                    position: absolute;

                
                    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
                    top: 20px;
                    left: 20px;
                    right: 20px;
                    bottom: 20px;
                    z-index: 2;
                    transition: ease all 2.3s;
                    transform-style: preserve-3d;
                    transform: translateZ(0px);
                  }`}
                </style>
                <style></style>


              <h1 className={`font-gabriela ${props.colorText_projet}`}>{props.nom_projet}</h1>
              <span className={`price font-poppins ${props.colorText_projet}`}>{props.techno_projet}</span>
              <div className={`card-back ${props.border_back_projet}`}
              
              >
                {
                props.isOnline&&(
                  <a href={props.onlineLink} target='_blank'><FontAwesomeIcon icon={faExternalLinkAlt} className='px-4'/>Voir</a>
                )
                }

                <a href='' onClick={handleClick}>Détails</a>
                <p className={`${props.colorText_projet}`}>{props.texte_projet}</p>
                <div className={`${props.colorText_projet}`}>{props.detail_tech_projet}</div>
              </div>
            </div>
          </div>
          </div>



</section>

  )



}

export default MagicCardUnit;