import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NewPresta.css';

const NewPresta = ( {title, description, icone }) => {
    return (
        <section className='containerBox'>
            <div className='box'>
                <div className='boxitems'>
                    <div className="iconefont">
                        <FontAwesomeIcon icon={icone} />
                    </div>
                    <h3>{ title }</h3>
                    <p>{description}</p>
                </div>
            </div>
        </section>
  );
}

export default NewPresta;
