import React from 'react';
import FalshBtn2 from '../FalshBtn2';
const Presentation = () => {
    const handleClick = () => {
        window.location.href = '#portfolio';
      };
    return(
    <section className='presentation'>
        <h1>
            <div className='first'>
                Je suis un professionnel du web
            </div> 
            <div className='second'>
                à l'écoute de vos besoins !
            </div>

        </h1>
        <div className='text-white'>

        <FalshBtn2 texte={"Découvrir"} color={"#C5B358"} handleClick={handleClick}/>
        </div>
        

        



    </section>
    )
}

export default Presentation;