import React,{useEffect} from 'react'
import {listeProjet} from '../../datas_files/listeProjet'
import { questionsReponses } from '../../datas_files/questionsReponse';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import BoutonAccordeon from '../BoutonAccordeon';
import { v4 as uuidv4 } from 'uuid';
import Neon from './Neon';
import Footer from '../Footer/Footer';
import ButtonFlash from '../ButtonFlash';
import FigureGif from './FigureGif';
import BackToTop from '../BackToTop';
import Menu from '../Menu/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLink, faExternalLinkAlt, faArrowRight, faSignOutAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
const DetailProjet = (props) => {
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
      if (parseInt(id) > 8 || isNaN(parseInt(id)) || parseInt(id) < 1) {
        navigate(`/`);
      }
      
    }, [id, navigate]);
    
    const projetData = (id<9 && id>0) ? listeProjet.find( x => x.id === id): false;

    const qrprojet = questionsReponses.find( x=> x.id === id)
    const handleClick = () => {
      parseInt(projetData.id)>1 ?  navigate(`/projet/${parseInt(projetData.id-1)}`) : navigate(`/`);


    };
    const handleClickNext = () => {
      const next = parseInt(projetData.id) +1
      navigate(`/projet/${next}`);
    };


  return (
    <section className='bg-gray-800'>
        <Menu/>
          <div className=''>

            <div className='
          h-[95px] 
          w-full opacity-90'/>

            
          </div>




    <section className='min-h-[500px]
    xl:max-w-[80%] mx-auto'>

    <div className='font-gabriela text-center py-5'>

        <div className='space-x-5'>
          {
            projetData.id &&
            <ButtonFlash texte={<FaArrowLeft />} handleClick={handleClick} idprojet={id}
            ></ButtonFlash>

          }
            {
              projetData.id && parseInt(projetData.id) <listeProjet.length &&
              <ButtonFlash texte={<FaArrowRight />} handleClick={handleClickNext} idprojet={id}
              ></ButtonFlash>

            }
        </div>
            
        <div className='text-red-500 text-[40px] flex justify-center'>
          {
            projetData.id && projetData.iconsTech.map(x =>
              <div className={`pt-5 px-3 ${x.color}`}>
                <x.icon /> 
              </div>
            )
          }
        </div>

        <div>
          <Neon text={projetData.nom} 
            couleurTexte={projetData.couleurTexteNeon}
            haloFaible0={projetData.couleurHaloFaible0}
            haloFaible1={projetData.couleurHaloFaible1}
            haloFort={projetData.couleurHaloFort}/>
        </div>
        <div className='flex flex-wrap justify-center mx-auto px-5 containerWords'>

          <div>
              {projetData.url && (
              <div className='linkWatch'>
                <FontAwesomeIcon className='iconeW' icon={faLink} /><a href={projetData.url} target="_blank" rel="noopener noreferrer">{projetData.url}</a>
              </div>
            )}
          </div>
          <div className='motsClesPres'>
                {
                  projetData.id && projetData.concepts.map( x =>
      <span className={`px-5 py-2 rounded-2xl mx-2 my-2 text-white text-[16px] border 
            border-yellow-200 shadow-md 
            bg-gradient-to-b from-gray-800 to-gray-900`}>{x}</span>

                  )
                }

          </div>

        </div>

        <div className='flex justify-center px-5 pt-10'>
          <p className={`text-yellow-600 text-justify text-[16px] px-5` }>{projetData.description}</p>
        </div>

        
        <section className='flex flex-col xl:flex-wrap xl:flex-row pt-5 max-w-[1200px] mx-auto justify-center
        ' key={uuidv4()}>
            { projetData.id && projetData.videoprez ?(
              projetData.gifpresentation.map(x =>
                <FigureGif texteFigure={x.legende} key={x.id} miniature={x.miniature}
                youtubeLink={x.youtubeLink}></FigureGif>)
                ):
            (
              <p className='text-white text-center'>vidéo de présentation: à venir</p>
              )
            }
        </section>

    </div>
    <div className='m-10 py-5'>
    {
      projetData.id && qrprojet.qr.map(x =>
        <BoutonAccordeon numeroLabel={uuidv4()} question={x.question} reponse={x.reponse} couleur={x.colorHexa} 
        couleurFondQuestion={"black"}
        couleurFondReponse={x.colorHexa}
        
        />)
    }

    </div>

    <BackToTop/>
    
    </section>

<div className='footerCls'>
    <Footer/>
</div>
    </section>
  )
}

export default DetailProjet