import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../Menu/Menu';
import '../../../src/articlecontent.css';
import BackToTop from '../BackToTop';
import ArticleNotAvailable from '../ArticleNotAvailable/ArticleNotAvailable';
import Footer from '../Footer/Footer';
import NotFound from '../NotFound/NotFound';
import Lottie from 'lottie-react';
import animationData from '../../../src/Animation-server.json';
const ArticleBook = () => {
    const [bookcontent, setBookContent] = useState(null);
    const { id } = useParams();
    const containerRef = useRef(null);

    useEffect(() => {
        fetch(`https://luckycheetah.fun/ronify/books/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Books fetched:', data);
                setBookContent(data);
            })
            .catch(error => console.error('Error fetching books:', error));
    }, [id]);

    if (parseInt(id) > 6 || parseInt(id) < 1 || !/^\d+$/.test(id)) {
        return <NotFound />;
    }
    const handleClick = (e) => {
        console.log("hello")
        const target = e.target;
        if (target.classList.contains('copy-button')) {
          const paragraph = target.parentElement.querySelector('span').textContent.trim();
          if (paragraph) {
            navigator.clipboard.writeText(paragraph).then( () => {
                target.textContent = "✨ c\'est copié !";
                // target.style.display = 'none';
                target.classList.add('infoboutoncopie');
                setTimeout(() => {
                    target.textContent = "copier"; // Revert back to "copier" after 1 second
                    target.style.display = 'inline'; // Show the button again
                    target.classList.remove('infoboutoncopie');
                }, 1500);
            }).catch((error) => {
                console.error('Erreur lors de la copie du texte : ', error);
            });
          }
        }
    }

    return (
        <section className='test'>
            <Menu />
            <section className="pt-[95px] background-['#03031A']"/>

            {bookcontent !== null && bookcontent.isAvailable ? (
                <p ref={containerRef} onClick={handleClick} dangerouslySetInnerHTML={{ __html: bookcontent.book_article_title }}></p>
            ) : (
                bookcontent !== null && <ArticleNotAvailable />
            )}




    {/* <div class='' onClick={handleClick}>
        <div class='tableDesMatieres'>
            <h1>Guide de survie: sécuriser son serveur Linux</h1>
            <p>
                <ul class='parag_table_matiere'>
                    <li><a href='#principe'>Adopte le principe du moindre privilège</a></li>
                    <li><a href='#ssh_connect'>Connecte toi en SSH</a></li>
                    <li><a href='#ssh_root'>Désactive l'accès SSH par le compte root</a></li>
                    <li><a href='#ssh_port'>Modifie le port SSH</a></li>
                    <li>Utilise SFTP au lieu de FTP</li>
                    <li><a href='#fail2ban'>Utilise Fail2ban</a></li>
                    <li>Ouvre uniquement les ports dont on a besoin</li>
                    <li><a href='#ipv6_off'>Désactive IP v6</a></li>
                    <li><a href='#miseAjour'>Automatise les mises à jour (unattended-upgrades)</a></li>
                    <li>Ne fais jamais confiance au client (navigateur)</li>
                    <li>Utilise des mots de passe uniques et compliqués</li>
                    <li>Sois plus rapide dans tes commandes</li>
                    <li>Installe un anti-malware</li>
                </ul>
            </p>
        </div>

        <div>
      <Lottie animationData={animationData} loop={true} className='lottieFileSM' />
    </div>
        <div class='sectionThematique'>
            Adopte le principe du moindre privilège
        </div>
        <div class='maincontainer_color' id='principe'>
            <div class='p1_g'>
                <h2 class='sectiongenerale'>Section 1: Points de repère</h2>
                <p>
                    Le principe du moindre privilège (Principle of Least Privilege en anglais) est 
                    un concept de sécurité informatique qui stipule que tout utilisateur, programme ou système doit avoir 
                    <span class='texte_important_rs'>uniquement les privilèges nécessaires</span> pour accomplir ses tâches autorisées, 
                    et aucun privilège supplémentaire. 
                </p>
                <p>
                    En d'autres termes, cela signifie accorder à chaque élément uniquement les autorisations dont il a besoin 
                    pour fonctionner correctement, sans donner un accès excessif qui pourrait être exploité de manière malveillante.
                </p>
                <p>
                    Ce principe vise à <span class='texte_important_rs'>réduire la surface d'attaque</span> d'un système en limitant l'étendue des droits accordés. 
                    Cela peut aider à minimiser les risques de compromission de sécurité, de propagation de logiciels malveillants 
                    et d'exploitation de failles de sécurité.
                </p>
                <p>
                    Dans un premier temps, une compréhension du système d'autorisations dans Linux est essentielle 
                    pour saisir pleinement le principe du moindre privilège. 
                </p>
                <h2 class='rubrique'>I / Le système de contrôle d’accès au fichier</h2>
                <p>
                    Dans un système Linux, le système de contrôle d'accès aux fichiers repose sur les permissions 
                    attribuées aux utilisateurs et aux groupes.
                </p>
            </div>
            <div class='p1_d'>
                <h3 class='sous_rubrique'>Propriétaire, groupe, autres utilisateurs :</h3>
                <table class='singlecoltable'>
                    <thead>
                        <tr>
                            <th>
                                Propriétaire
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <li>
                                    Chaque fichier et répertoire dans un système Linux a un propriétaire.
                                </li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li>
                                    Le propriétaire initial d'un fichier est généralement l'utilisateur qui l'a créé.
                                </li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li>
                                    Le propriétaire a le <span class='texte_important_rs'>contrôle total </span>sur le fichier, y compris le droit de le lire, de l'écrire et de l'exécuter.
                                </li>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class='singlecoltable'>
                    <thead>
                        <tr>
                            <th>
                                Groupe propriétaire
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <li>
                                    En plus du propriétaire, chaque fichier et répertoire est associé à un groupe propriétaire.
                                </li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li>
                                    Tous les utilisateurs qui font partie de ce groupe ont des permissions spécifiques sur le fichier ou le répertoire.
                                </li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li>
                                    Les permissions accordées au groupe sont généralement <span class='texte_important_rs'>moins étendues </span>que celles accordées au propriétaire.
                                </li>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class='singlecoltable'>
                    <thead>
                        <tr>
                            <th>
                                Autres utilisateurs 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <li>
                                    Les utilisateurs qui ne sont ni le propriétaire du fichier ni membres du groupe propriétaire sont classés comme “autres utilisateurs”.
                                </li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li>
                                    Les permissions accordées à ces autres utilisateurs sont généralement <span class='texte_important_rs'>les plus restrictives</span>.
                                </li>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>


            </div>
        </div>

        <div class='maincontainer'>
            <div class='p1_g'>
                <p>
                    Pour afficher la liste de tous les utilisateurs du système, vous pouvez utiliser la commande :
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo getent passwd</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>getent</td>
                            <td>
                                C'est une commande qui est utilisée pour interroger les bases de données de noms du système. <br></br>
                                Le nom "getent" est une abréviation de <span class='texte_important'>get entries</span>. Cette commande permet d'interroger différentes sources de données du système pour obtenir des informations sur les utilisateurs, les groupes, les hôtes, les services, etc.
                            </td>
                        </tr>
                        <tr>
                            <td>passwd</td>
                            <td>
                                C'est l'une des bases de données de noms du système. Dans ce contexte, "passwd" fait référence à la base de données des comptes d'utilisateurs du système. <br></br>
                                Cette base de données est généralement stockée dans le fichier /etc/passwd sur la plupart des systèmes Linux. Ce fichier contient des informations sur chaque compte utilisateur, telles que le nom d'utilisateur, l'identifiant d'utilisateur (UID), l'identifiant de groupe (GID), le répertoire personnel, le shell, etc. 
                            </td>
                        </tr>

                    </tbody>

                </table>


            </div>
            <div class='p1_d'>
                <p>Voyons ce que cette commande nous renvoie:</p>
            <img
                    src='../images/screenshots_for_books/securate_vps_46.png'  
                />


            </div>


        </div>

        <div class='maincontainer_color'>
            <div class='p1_g'>
                <h2 class='question'>Pourquoi il y a des noms de logiciels ? Je m’attendais à voir des noms d’utilisateurs uniquement !</h2>
                <p>
                    Dans le contexte du système Linux, le terme utilisateur est utilisé de manière générale pour désigner à la fois les utilisateurs humains <span class='texte_important'>et les entités système</span>.
                </p>
                <p>
                La commande getent passwd affiche les informations des comptes d’utilisateurs du système. Chaque ligne représente un compte utilisateur et est structurée comme suit :
                </p>
                <p class='formula'>
                    nomUtilisateur :mdp :UID :GID :infos :repertoirePersonnel :shell
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>nom_utilisateur </td>
                            <td>Le nom de l'utilisateur</td>
                        </tr>
                        <tr>
                            <td>mdp</td>
                            <td>Ce champ contient un caractère unique (x) qui indique que le mot de passe de l'utilisateur est stocké dans le fichier /etc/shadow.</td>
                        </tr>
                        <tr>
                            <td>UID</td>
                            <td>L'identifiant de l'utilisateur. Chaque utilisateur a un UID unique.</td>
                        </tr>
                        <tr>
                            <td>GID</td>
                            <td>L'identifiant du groupe principal de l'utilisateur. Chaque utilisateur appartient à un groupe principal.</td>
                        </tr>
                        <tr>
                            <td>infos</td>
                            <td>Il peut s'agir d'informations supplémentaires sur l'utilisateur, telles que son nom complet.</td>
                        </tr>
                        <tr>
                            <td>répertoire_personnel </td>
                            <td>Le chemin vers le répertoire personnel de l'utilisateur.</td>
                        </tr>
                        <tr>
                            <td>shell</td>
                            <td>Le shell par défaut de l'utilisateur, qui est utilisé lorsqu'il se connecte au système.</td>
                        </tr>

                    </tbody>

                </table>



            </div>
            <div class='p1_d'>
            <h2 class='question'>/bin/false, /sbin/nologin, ça veut dire quoi ?</h2>
                <h3 class='sous_rubrique'>bin/false</h3>
                <p>
                    Lorsque /bin/false est défini comme le shell d'un utilisateur, cela signifie que cet utilisateur <span class='texte_important_rs'>ne peut pas ouvrir une session interactive</span> sur le système.
                </p>
                <ul class='enumeration'>
                    <li>
                        Quand un utilisateur avec /bin/false comme shell essaie de se connecter, il est immédiatement déconnecté. <span class='texte_important'>Il n'obtient pas d'invite de commande</span>.
                    </li>
                    <li>
                        Cela est souvent utilisé pour les comptes système qui n'ont pas besoin d'accès interactif, comme les <span class='texte_important'>comptes associés à des services</span>.
                    </li>
                </ul>

                <h3 class='sous_rubrique'>sbin/nologin</h3>
                <p>
                    La spécification /usr/sbin/nologin pour le shell signifie que l'utilisateur ne peut pas ouvrir de session interactive sur le système, que ce soit via SSH ou d'autres moyens.
                </p>
                <ul class='enumeration'>
                    <li>
                        Quand un utilisateur avec /sbin/nologin comme shell essaie de se connecter en ouvrant une session interactive (par exemple, via SSH), il reçoit un message qui lui indique qu'il <span class='texte_important'>n'est pas autorisé à ouvrir une session</span>.
                    </li>
                    <li>
                        Par exemple, dans le contexte de l'utilisateur système mail, qui est généralement utilisé pour les processus de livraison de courrier, 
                        lui attribuer /usr/sbin/nologin comme shell signifie qu'il ne peut pas se connecter directement au système. 
                        Cette configuration est destinée à limiter l'accès interactif à cet utilisateur, qui est généralement réservé à des tâches spécifiques liées à la livraison de courrier.
                    </li>
                </ul>

            </div>

        </div>

        <div class='maincontainer'>
            <div class='p1_g'>
                <h2 class='rubrique'>II / Le système de permissions</h2>
                <h3 class='sous_rubrique'>1 - La notation symbolique:</h3>
                <p>
                    Cette notation permet de spécifier les autorisations de manière plus explicite en utilisant des lettres et des symboles pour représenter les autorisations de lecture (r), écriture (w), et exécution (x), ainsi que d'autres paramètres comme le propriétaire (u), le groupe (g), et les autres utilisateurs (o).
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>r</td>
                            <td>Lecture (read)</td>
                        </tr>
                        <tr>
                            <td>w</td>
                            <td>Écriture (write)</td>
                        </tr>
                        <tr>
                            <td>x</td>
                            <td>Exécution (execute)</td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td>Aucune permission</td>
                        </tr>

                    </tbody>

                </table>

                <p>
                    Dans cette notation, chaque ensemble de trois caractères représente les autorisations pour le propriétaire, le groupe, 
                    et les autres utilisateurs, dans cet ordre.
                </p>

                <p>Voyons l'exemple du dossier sudoers.d qu'on trouve dans le repertoire etc:</p>
                <img
                    src='../images/screenshots_for_books/securate_vps_48.png'  
                />
                <ul class='enumeration'>
                    <li>
                        Le premier bloc d'autorisations: <span class='texte_important'>rwx</span><br></br>
                        Ce sont les permissions du propriétaire. Ces permissions indique que le propriétaire (root)
                        peut lire, écrire et parcourir le répertoire.
                    </li>
                    <li>
                        Le bloc suivant: <span class='texte_important'>r-x</span><br></br>
                        Ce sont les permissions du groupe auquel appartient le répertoire. Dans cet exemple, le
                        groupe est également root. Les membres du groupe root peuvent lire et parcourir le répertoire
                        mais ne peuvent pas y écrire.
                    </li>
                    <li>
                        Enfin, le troisième bloc:<span class='texte_important'>---</span><br></br>
                        Ce sont les permissions pour les autres utilisateurs qui ne sont ni le propriétaire du fichier ni membres du groupe. 
                        Ils n'ont aucun droit: ils ne peuvent ni lire, ni écrire, ni parcourir le fichier.
                    </li>
                </ul>
            </div>
            <div class='p1_d'>
                <h2 class='question'>
                    C’est quoi la lettre d au début du premier bloc ?
                </h2>
                <p>La première lettre, qui peut également être un tiret, symbolise <span class='texte_important'>l'entité de système de fichier.</span></p>
                <p>Dans un système Unix/Linux, ils sont symbolisés de cette manière:</p>
                <table>
                    <tbody>
                        <tr>
                            <td>-</td>
                            <td>Fichier ordinaire: un <span class='texte_important'>fichier standard</span> contenant des données.</td>
                        </tr>
                        <tr>
                            <td>d</td>
                            <td>Répertoire: une <span class='texte_important'>structure</span> qui contient des fichiers et d'autres répertoires.</td>
                        </tr>
                        <tr>
                            <td>l</td>
                            <td>Lien symbolique: Un <span class='texte_important'>raccourci ou un alias </span>pointant vers un autre fichier ou répertoire.</td>
                        </tr>
                        <tr>
                            <td>c</td>
                            <td>
                                Fichier spécial de périphérique de caractères<br></br>
                                Ce type de fichier est utilisé pour représenter des périphériques de caractères, tels que <span class='texte_important'>les ports série et les terminaux</span>. Ils sont généralement situés dans le répertoire /dev.
                            </td>
                        </tr>
                        <tr>
                            <td>b</td>
                            <td>
                                Fichier spécial de périphérique de blocs<br></br>
                                Ce type de fichier est utilisé pour représenter des périphériques de blocs, tels que les <span class='texte_important'>disques durs et les partitions</span>. Ils sont également situés dans le répertoire /dev.

                            </td>
                        </tr>
                        <tr>
                            <td>p</td>
                            <td>
                                Tube nommé (fifo). Aussi appelé pipe nommé, il s'agit d'un type de fichier spécial qui <span class='texte_important'>permet la communication entre deux processus</span>. Il est utilisé pour transférer des données entre les processus de manière similaire à un tube anonyme (pipe), mais il possède un nom dans le système de fichiers.

                            </td>
                        </tr>
                        <tr>
                            <td>s</td>
                            <td>
                                Socket. Les sockets sont utilisés pour la <span class='texte_important'>communication inter-processus (IPC)</span> sur un réseau informatique. Ils permettent à deux processus sur le même ordinateur ou sur différents ordinateurs de communiquer.
                            </td>
                        </tr>

                    </tbody>

                </table>







            </div>
        </div>

        <div class='maincontainer_color'>
        <div class='p1_g'>
            <p>Voyons quelques exemples qui nous permettront de voir où on peut trouver ces différentes entités.</p>
            <h3 class='sous_rubrique'>Exemples d'entités de système de fichier</h3>
                
                
            <p>Un <span class='texte_important'>fichier standard contenant des données (-)</span> dans le répertoire /etc:</p>
            <img src='../images/screenshots_for_books/securate_vps_55.png' />
                
                <p>Un <span class='texte_important'>répertoire (d)</span> du gestionnaire de paquets snap:</p>
            <img src='../images/screenshots_for_books/securate_vps_49.png' />

                <p>Un <span class='texte_important'>lien symbolique (l)</span> dans /var/run qui pointe vers un autre répertoire:</p>
            <img src='../images/screenshots_for_books/securate_vps_50.png' />

                <p>Un <span class='texte_important'>fichier de type de caractère (c)</span> dans /dev qui représente un port série:</p>
            <img src='../images/screenshots_for_books/securate_vps_51.png' />

                <p>Un <span class='texte_important'>fichier spécial de périphérie de blocs (b)</span> dans /dev:</p>
            <img src='../images/screenshots_for_books/securate_vps_52.png' />
            
                <p>Un <span class='texte_important'>tube nommé(p)</span> ou pipe nommé dans /var/run :</p>
            <img src='../images/screenshots_for_books/securate_vps_53.png' />
            
                <p>Un <span class='texte_important'>socket (s) </span>de domaine Unix dans /var/run:</p>
            <img src='../images/screenshots_for_books/securate_vps_54.png' />

            <p>Avec la commande suivante, on peut obtenir le type d'une entité:</p>
            <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>stat nomDuFichier</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>Par exemple:</p>
                <img src='../images/screenshots_for_books/securate_vps_56.png' />



        </div>
        <div class='p1_d'>
        <h3 class='sous_rubrique'>2 - La notation octale:</h3>
                <p>
                    Il existe également une autre écriture pour décrire les droits d'accès sur une entité: la notation octale.
                </p>
                <p>
                    La notation octale est un système de numération en base 8. 
                    Contrairement au système décimal (base 10) que nous utilisons couramment, 
                    où chaque chiffre peut varier de 0 à 9, dans le système octal chaque chiffre peut varier de 0 à 7. 
                </p>
                <p>
                    La notation octale est souvent utilisée en informatique pour représenter des valeurs 
                    binaires de manière plus concise et facilement compréhensible par les humains.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Signification</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>4</td>
                            <td>
                                Autorisation de lecture (r) Permet à l'utilisateur de voir le contenu du fichier 
                                s'il est autorisé à le lire.
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>
                                Autorisation d'écriture (w) Permet à l'utilisateur de modifier ou d'ajouter du 
                                contenu au fichier s'il est autorisé à l'écrire.
                            </td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>
                                Autorisation d'exécution (x) Permet à l'utilisateur d'exécuter le fichier s'il 
                                s'agit d'un programme exécutable ou d'un script.
                            </td>
                        </tr>
                    </tbody>


                </table>
                <p>
                    En additionnant ces chiffres, on obtient la représentation octale des permissions pour chaque groupe d'utilisateur:
                </p>
                <ul class='enumeration'>
                    <li>7 ➡ Lecture (4) + Écriture (2) + Exécution (1) - Permet à l'utilisateur de lire, écrire et exécuter le fichier.</li>
                    <li>6 ➡ Lecture (4) + Écriture (2) - Permet à l'utilisateur de lire et d'écrire dans le fichier, mais pas de l'exécuter.</li>
                    <li>5 ➡ Lecture (4) + Exécution (1) - Permet à l'utilisateur de lire et d'exécuter le fichier, mais pas de l'écrire.</li>
                    <li>4 ➡ Lecture (4) - Permet à l'utilisateur de lire le contenu du fichier, mais pas de l'écrire ou de l'exécuter.</li>
                    <li>3 ➡ Ecriture (2) + Exécution (1) - Permet à l'utilisateur d'écrire et d'exécuter le fichier</li>
                    <li>2 ➡ Ecriture (2) - Permet à l'utilisateur d'écrire dans le fichier uniquement</li>
                    <li>1 ➡ Exécution (1) - Permet à l'utilisateur d'exécuter le fichier. En revanche il ne peut ni le lire ni écrire dans le fichier.</li>
                    <li>0 ➡ Aucune permission - Ne permet à l'utilisateur ni de lire, ni d'écrire, ni d'exécuter le fichier.</li>
                </ul>
                <p>
                    Avec la commande suivante, on peut savoir, en notation octale, quelles sont
                    les droits d'accès sur une entité:
                </p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>stat -c "%a %n" nomDuFichier</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>Par exemple:</p>
                <img src='../images/screenshots_for_books/securate_vps_57.png' />
                <ul class='enumeration'>
                    <li>6: Le <span class='texte_important'>propriétaire</span> du fichier a le droit de lire et d'écrire dans le fichier shadow.</li>
                    <li>4: Le <span class='texte_important'>groupe propriétaire</span> peut seulement lire</li>
                    <li>0: Les <span class='texte_important'>autres utilisateurs</span> n'ont aucun droit.</li>
                </ul>

        </div>

        </div>

        <div className='maincontainer'>
        <div class='p1_g'>
        <h2 class='sectiongenerale'>Section 2: Le principe du moindre privilège en pratique</h2>
            <p>
                Maintenant que nous avons vu dans la première partie le fonctionnement des droits d'accès d'une entité dans un système Linux/Unix, nous allons 
                voir comment tout cela se matérialise et quelles sont les bonnes pratiques à adopter.
            </p>
            <h3 class='sous_rubrique'>évite d'utiliser le compte root...</h3>
            <p>
                Le compte root a un accès complet au système, ce qui le rend vulnérable aux attaques en cas de compromission. 
                En utilisant un utilisateur normal, <span class='texte_important'>vous limitez les risques d'accès non autorisé</span> au système.
            </p>
            <p>
                Toutefois il y aura toujours des tâches à exécuter qui nécessiteront des privilèges sudo: il est alors 
                possible d'attribuer à un utilisateur des <span class='texte_important'>privilèges sudo.</span>
                En d'autres termes, un <span class='texte_important'>sudoer</span> est un utilisateur qui est autorisé à utiliser la commande sudo pour exécuter des commandes 
                avec les privilèges de superutilisateur (root) sur le système.
            </p>
            <h3 class='sous_rubrique'>crée plutôt un utilisateur...</h3>
            <p>
                Avec la commande ci-dessous, nous allons pouvoir créer un utilisateur pour notre système Linux, ce que l'on peut faire uniquement en étant root ou membre du groupe sudo.
            </p>
            <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo adduser nom_utilisateur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
        </div>


        <div class='p1_d'>
            <img src='../images/screenshots_for_books/securate_vps_64.png' />
            <p>
            Lorsque vous créez un nouvel utilisateur sur un système Linux, un répertoire utilisateur est généralement automatiquement 
            créé dans le répertoire /home. C'est ce que nous voyons ici avec notre utilisateur causticmonkey: un répertoire /home/causticmonkey a été créé pour stocker les fichiers personnels de cet utilisateur.
            </p>
            <h3 class='sous_rubrique'>Et accorde-lui des privilèges sudo (si besoin)...</h3>
            <p>Voyons maintenant comment accorder des privilèges sudo à notre utilisateur lambda. Là encore pour pouvoir donner les pouvoirs de sudo à un utilisateur, il 
                faut être soit même un sudoer ou bien root.
            </p>
            <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo usermod -aG sudo causticmonkey</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <img src='../images/screenshots_for_books/securate_vps_65.png' />
        </div>

        </div>





        <div class='maincontainer_color'>
            <div class='p1_g'>
                <p>Notre utilisateur causticmonkey est maintenant membre du groupe sudo. Il aura désormais des privilèges sudo sur le système. 
                    Concrètement cela signifie qu'<span class='texte_important'>il peut utiliser la commande sudo </span>suivi de n'importe quelle commande pour l'exécuter avec les privilèges de superutilisateur.
                </p>
                <p>Vérifions cela. Pour changer d'utilisateur on peut faire su suivi du nom de l'utilisateur:</p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>su causticmonkey</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>Nous sommes maintenant connecté avec l'utilisateur causticmonkey:</p>
                <img src='../images/screenshots_for_books/securate_vps_66.png' />

                <p>Voyons s'il peut exécuter une commande sudo:</p>
                    
                    
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo -s</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <img src='../images/screenshots_for_books/securate_vps_67.png' />
               

                
            </div>
            <div class='p1_d'>
                <p>
                    Cette commande permis d'ouvrir un shell en tant que superutilisateur (root), ce que peut donc faire l'utilisateur que nous venons de créer.
                </p>
                <p>
                    Lorsque l'utilisateur n'a plus besoin de ces privilèges root, il peut simplement taper exit:
                </p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>exit</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <img src='../images/screenshots_for_books/securate_vps_68.png' />
                <h2 class='question'>
                    Quelle différence entre sudo -s et sudo ?
                </h2>
                <p>
                    Avec sudo -s, une fois que vous avez ouvert le shell interactif avec les privilèges root, toutes les commandes que vous exécutez dans ce shell <span class='texte_important'>bénéficieront automatiquement des privilèges root.</span> 
                </p>
                <p>
                    Vous n'aurez pas besoin de taper sudo pour chaque commande individuelle, car vous êtes déjà en mode root. Cela peut être pratique lorsque vous devez exécuter plusieurs commandes nécessitant des privilèges root successivement, car 
                    <span class='texte_important'>vous n'avez pas à retaper votre mot de passe à chaque fois</span> contrairement à la commande sudo qui demandera le mot de passe de l'utilisateur à chaque commande sudo.
                </p>


            </div>


        </div>




        <div class='maincontainer'>
            <div class='p1_g'>
                <h3 class='sous_rubrique'>Restriction d'accès: utilisateur sudo</h3>
                <p>
                    Prenons l'exemple du répertoire (sens de la lettre d) sudoers.d dont l'accès est restreint au propriétaire
                    root (rwx) et au groupe root (r-x). Un utilisateur étant ni propriétaire 
                    ni membre du groupe root n'aura aucun droit sur ce répertoire: c'est ce qu'on peut lire 
                    ci-dessous dans la capture d'écran (les 3 tirets: ---).
                </p>
                <img src='../images/screenshots_for_books/securate_vps_58.png' />
                <p>
                    Mettons cela en pratique. On se connecte avec un utilisateur lambda. Si celui-ci tente 
                    d'accéder au repertoire sudoers.d voici ce qu'il verra:
                </p>
                <img src='../images/screenshots_for_books/securate_vps_59.png' />
                <p>
                    Si mon utilisateur est ajouté au groupe sudo, il pourra exécuter la commande sudo -s. Les commandes exécutées 
                    dans ce shell seront exécutées avec les privilèges de superutilisateur
                </p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo -s</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>
                    En étant superutilisateur, il peut alors accéder au dossier sudoers.d et à son contenu:
                </p>
                <img src='../images/screenshots_for_books/securate_vps_60.png' />



            </div>
            <div class='p1_d'>
                <h3 class='sous_rubrique'>Restriction d'accès: utilisateur non sudo</h3>
                <p>
                    Intéressons-nous à présent au point de vue d'un utilisateur non sudo qui 
                    voudrait accéder au répertoire sudoers.d sur lequel il n'a aucun droit d'accès.
                </p>
                <p>
                    Sans surprise, s'il tente d'y accéder sans élévation de privilèges, il obtient un 
                    message d'erreur: permission denied.
                </p>
                <img src='../images/screenshots_for_books/securate_vps_61.png' />
                <p>
                    Voyons maintenant ce qui se passerait si notre utilisateur lambda décidait de 
                    taper la commande sudo -s:
                </p>
                <img src='../images/screenshots_for_books/securate_vps_62.png' />
                <p>
                    Bien que son mot de passe lui soit demandé, le système comprend que cet utilisateur 
                    n'est pas membre du groupe sudo. La différence avec la situation précédente c'est que 
                    cette fois, l'utilisation de la commande sudo -s par un utilisateur non sudo est enregistrée 
                    comme étant un incident.
                </p>
                <h2 class='question'>
                    Concrètement, où est signalé cet incident ?
                </h2>
                <p>
                    Cet incident signalé lors de la tentative d'utilisation de sudo -s est 
                    typiquement enregistré dans les fichiers journaux système. Dans la plupart des distributions Linux, 
                    les événements liés à sudo sont consignés dans le fichier /var/log/auth.log ou /var/log/messages.
                </p>
                <p>
                    Un utilisateur du groupe sudo pourra accéder à ce journal via la commande:
                </p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo cat /var/log/auth.log</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>Et voici ce qu'il verra:</p>
                <img src='../images/screenshots_for_books/securate_vps_63.png' />
            </div>

        </div>

        






        <div class='sectionThematique'>
            Connecte toi en SSH
        </div>
        <div class='maincontainer' id='ssh_connect'>
            <div class='p1_g'>
                <h2 class='etape'>Principe:</h2>
                <p>
                    Lorsque vous vous connectez en SSH, toutes les données échangées entre votre ordinateur et le serveur sont chiffrées.
                </p>
                <p>
                    Avec SSH, vous utilisez généralement une paire de clés : une clé publique et une clé privée. Cette méthode est souvent considérée comme une forme d'authentification à deux facteurs. Pour se connecter, une personne doit avoir la clé privée, qui est stockée sur son ordinateur, ainsi que la connaissance du mot de passe pour déverrouiller cette clé. Cela rend plus difficile pour les pirates d'accéder à votre compte, car ils auraient besoin à la fois de votre clé privée et de votre mot de passe.
                </p>
                <p>
                    Grâce au chiffrement des données, à l'authentification à deux facteurs et à la résistance aux attaques par force brute, 
                    la connexion en SSH est robuste, contrairement à la connexion via un mot de passe pour se connecter à 
                    un système distant.
                </p>                  
            </div>
            <div class='p1_d'>
                <p>SSH utilise un protocole de chiffrement. Il en existe plusieurs, mais les plus
                    connus et les plus employés sont:
                </p>
                    <ul class='enumeration'>
                        <li>
                            RSA (l’acronyme est formé des initiales de ses trois inventeurs : Rivest, Shamir, et Adleman), 
                            qui repose sur le principe de la factorisation de grands nombres.
                        </li>
                        <li>
                            <span>
                                Ed25519 ou ECDA (Elliptic Curve Digital Signature Algorithm) repose sur des opérations mathématiques 
                                basées sur les courbes d'Edwards tordues.
                            </span>
                            <span>
                                Ces opérations incluent généralement l'addition de points, la multiplication d'un point par un scalaire 
                                (un nombre entier), et d'autres opérations spécifiques à la structure de la courbe. 
                                Ces opérations sont utilisées pour générer des clés, chiffrer des données et créer des signatures numériques.
                            </span>

                            <span>Pour les plus curieux, voici à quoi ressemble l'équation de la courbe d'Edward tordue:
                                <img src='../images/screenshots_for_books/securate_vps_20.png' />
                            </span>
                            <span>
                                Ces deux protocoles de chiffrement sont asymétriques.
                            </span>
                        </li>
                    </ul>
            </div>
        </div>
        <div class='maincontainer_color'>
            <div class='p1_g'>
                <h2 class='question'>
                    C’est quoi un algorithme de chiffrement asymétrique ?
                </h2>
                <p>
                    C’est un système de cryptage qui utilise une paire de clés distinctes 
                    pour le chiffrement et le déchiffrement des données. 
                </p>
                <p>
                    Contrairement aux algorithmes de chiffrement symétrique qui utilisent une seule clé pour les deux opérations, les algorithmes de chiffrement asymétrique 
                    utilisent une clé publique et une clé privée.
                </p>
                <ul class='enumeration'>
                    <li>
                        Clé publique : Cette clé est utilisée pour chiffrer les données. <span class='important_ok'>Elle peut être distribuée publiquement et partagée avec n'importe qui.</span>
                    </li>
                    <li>
                        Clé privée : Cette clé est utilisée pour déchiffrer les données. <span class='important_danger'>Elle doit être gardée secrète et connue uniquement par le destinataire légitime.</span>
                    </li>
                </ul>             
            </div>

            <div class='p1_d'>
                <h2 class='question'>SSH c’est quoi ? ça fonctionne comment ?</h2>
                <div>
                    <p>
                        SSH, abréviation de 'Secure Shell', est un protocole de communication sécurisé utilisé 
                        pour accéder à des systèmes distants. 
                    </p>
                    <p>
                        Il fonctionne en utilisant un système de clés publiques et privées 
                        pour authentifier les utilisateurs.
                    </p>
                    <p>
                        Lorsqu'un utilisateur se connecte à un serveur distant via SSH, 
                        le serveur vérifie l'identité de l'utilisateur en utilisant la clé publique correspondante stockée 
                        dans le fichier 'authorized_keys' situé dans le répertoire '~/.ssh' de l'utilisateur sur le serveur 
                        distant. Cette méthode d'authentification garantit un échange sécurisé de données entre 
                        l'utilisateur et le serveur, protégeant ainsi les informations sensibles des interceptions 
                        non autorisées.
                    </p>
                </div>
                    <h2 class='question'>RSA ou Ed25519 ?</h2>
                    <p>
                        Ed25519 est plus sécurisé que RSA en raison de sa conception basée sur les courbes elliptiques, 
                        qui offrent une sécurité élevée avec des clés plus courtes. Contrairement à RSA, qui repose sur 
                        la factorisation de grands nombres, Ed25519 résiste à de nombreuses attaques cryptographiques modernes 
                        grâce à des mathématiques complexes et à une taille de clé plus petite, assurant une meilleure 
                        efficacité et une sécurité renforcée.
                    </p>

            </div>

        </div>


        <div class='maincontainer'>
            <div class='p1_g'>
                <h2 class='etape'>Étape 1 : Générer une paire de clés</h2>
                <p>Nous allons générer une paire de clés (une clé publique et une clé privée)
                 qui utilisera le protocole de chiffrement ed25519, via la commande suivante:
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh-keygen -t ed25519</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>
                    SSH demande le chemin d'enregistrement de cette paire de clé, ainsi 
                    qu'une passphrase pour la clé privée. Le choix de la passphrase (qui est comme un mot 
                    de passe qui va protéger l'accès à la clé privée) est important: tout comme un mot 
                    de passe, il faut choisir une combinaison robuste (par exemple: 40 caractères, sans répétition, 
                    majuscules, minuscules, chiffres, caractères spéciaux).
                </p>
                <img
                    src='../images/screenshots_for_books/securate_vps_21.png'  
                />
                <p>
                    SSH génère alors la paire de clés:
                </p>
                <img
                    src='../images/screenshots_for_books/securate_vps_22.png'  
                />

            </div>
            <div class='p1_d'>
                <p>Voyons un peu plus en détail les fichiers que SSH a fabriqué:</p>
                <p>
                    Vous pouvez taper la commande suivante si vous avez laissé le chemin 
                    par défaut pour la sauvegarde des clés:
                </p>
                
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>.ssh ls</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>




                <img
                    src='../images/screenshots_for_books/securate_vps_23.png'  
                />
                <ul class='enumeration'>
                    <li>
                        <p><span class='important_danger'>id_ed25519</span> : C'est votre clé privée.</p>
                        <p>Vous ne devez jamais partager ce fichier avec quiconque.</p>
                    </li>
                    <li>
                        <p><span class='important_ok'>id_ed25519.pub</span> : C'est votre clé publique.</p>
                        <p>Vous pouvez partager ce fichier avec des serveurs ou des services qui requièrent votre clé publique pour vous authentifier.</p>
                    </li>
                </ul>
                <p>
                    Voyons à quoi ressemble la clé publique. Pour ce faire, vous pouvez taper la commande
                    suivante:
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>cat id_ed25519.pub</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>



                <img
                    src='../images/screenshots_for_books/securate_vps_24.png'  
                />
                <legend>Une clé publique ed25519 tient sur une seule ligne</legend>

                
            </div>
        </div>

        <div class='maincontainer_color'>
            <div class='p1_g'>
                <h2 class='etape'>Étape 2 : côté serveur, créer un dossier .ssh dans le répertoire de l'utilisateur</h2>
                <p>Le serveur distant doit maintenant connaitre la clé publique de l'utilisateur lié à la paire de clé ed25519 que nous venons de 
                    générer. 
                </p>
                <p>
                    Pour faire cela, il faut créer un dossier '.ssh' dans le repertoire personnel de l'utilisateur (généralement dans /home/nomDeLutilisateur) via 
                    la commande:
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo mkdir /home/nomDeLutilisateur/.ssh</span>
                         <button class='copy-button'>copier</button>
                    </p>
                </p>

                <p>
                    Avant d'aller plus loin, il faut attribuer les bons droit d'accès ainsi que
                    le bon propriétaire du dossier.
                </p>
                
                    <p class='titre_important'>droits d'accés:</p>
                    <p>
                        Seul l'utilisateur propriétaire du répertoire doit 
                        pouvoir accéder à son répertoire '.ssh' (lecture, écriture, exécution) pour garantir 
                        que personne d'autre n'y aura accès.
                    </p>
                    <p>
                        Pour faire cela, on va utiliser la commande suivante:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                    <p class='consoleview'>
                        <p class='ligneDeCommande'>
                            <span>sudo chmod 700 .ssh</span>
                            <button class='copy-button'>copier</button>
                        </p>
                    </p>




                    <p>Pour plus de précision sur la notation octale pour chmod, consulter le guide de survie Linux.</p>
                    <p>Sans rentrer dans les détails, le 700 correspond à: </p>
                    <p>4 (lecture, ou 'r') + 2 (écriture, ou 'w') + 1 (exécution ou 'x')</p>
                    <p class='titre_important'>Propriétaire:</p>
                    <p>Pour attribuer le propriétaire du dossier '.ssh', on va utiliser la commande chown:</p>



                    <span class='intituleTerminal'>Terminal</span>
                    <p class='consoleview'>
                        <p class='ligneDeCommande'>
                            <span>sudo chown nomDuProprietaire:nomDuGroupe</span>
                            <button class='copy-button'>copier</button>
                        </p>
                    </p>



                    <p>
                        l'utilisateur est le propriétaire et membre du groupe associé au répertoire. On a donc
                        ceci pour notre utilisateur noisyparrot:
                    </p>
                    <img
                    src='../images/screenshots_for_books/securate_vps_25.png'  
                />
                



            </div>


            <div class='p1_d'>
            <h2 class='etape'>Étape 3 : côté serveur, créer un fichier dans le répertoire de l'utilisateur</h2>
            <p>à l'intérieur de ce dossier '.ssh' que nous venons de créer, nous allons créer un fichier 'authorized_keys'</p>
            <p>On peut faire cela avec la commande suivante:</p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>touch authorized_keys</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>

            <p>
                De la même manière, que pour le dossier, on définit les droits d'accès et le propriétaire du fichier et 
                avec les deux commandes suivantes on s'assure que le propriétaire est bien notre utilisateur et que lui seul
                peut lire, modifier, exécuter le fichier:
            </p>

            <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo chmod 700 authorized_keys</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>

            <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo chown nomDuProprietaire:nomDuGroupe</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>



            <img src='../images/screenshots_for_books/securate_vps_26.png' />

            <h2 class='etape'>Étape 4 : copier la clé publique dans le fichier authorized_keys</h2>
            <p>
                Pour que le serveur puisse authentifier l'utilisateur, il doit connaître sa clé publique.
                Nous allons donc copier le contenu du fichier id_ed25519.pub (obtenu à l'étape 1) et le coller 
                dans le fichier authorized_keys que nous avons précédemment crée:
            </p>
            <img src='../images/screenshots_for_books/securate_vps_27.png' />
            <p>On peut enregistrer les modification (controle + X avec Nano)</p>
            </div>

        </div>

        <div class='maincontainer'>
            <div class='p1_g'>
                <h2 class='etape'>Étape 4 : Activer l'authentification par clé</h2>
                <p>
                    <p>
                        Maintenant que la clé publique est connue du serveur, SSH nous donne la 
                        possibilité de connecter notre utilisateur de 2 manières: via la passphrase 
                        (définie à l'étape 1) ou via le mot de passe de l'utilisateur. 
                    </p>
                    <p>
                        Voyons les avantages et inconvénients de chaque possibilités:
                    </p>
                    <ul class='enumeration'>
                        <li>
                            <p>Passphrase de la clé privée : Utiliser une passphrase pour protéger votre clé privée ajoute une <span class='important_ok'>couche de sécurité supplémentaire.</span></p> 
                            <p>Cela signifie que même si quelqu'un accède à votre clé privée, il ne pourra pas l'utiliser pour se connecter à vos serveurs sans connaître la passphrase associée. Cela peut être particulièrement utile si vous stockez votre clé privée sur un appareil mobile ou si vous la partagez entre plusieurs ordinateurs.</p>
                        </li>

                        <li>
                            <p>
                                Mot de passe de l'utilisateur : Se connecter en utilisant le mot de passe de l'utilisateur est <span class='important_ok'>plus simple et plus direct.</span> 
                            </p>
                            <p>
                                Cependant, cela signifie que la sécurité de <span class='important_danger'>votre connexion SSH dépend entièrement de la sécurité de votre mot de passe d'utilisateur.</span> Assurez-vous d'utiliser un mot de passe fort et unique pour réduire les risques d'accès non autorisé.
                            </p>
                        </li>
                    </ul>
                    <p>
                        Dans de nombreux cas, il est recommandé d'utiliser une passphrase avec votre clé privée pour une sécurité renforcée.
                    </p>

                </p>
            </div>

            <div class='p1_d'>
                <p>Nous allons donc modifier le fichier de configuration SSH:</p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo nano /etc/ssh/sshd_config</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>
                    Et on va s'assurer d'avoir la propriété PubkeyAuthentication sur yes (c'est sa valeur par défaut, on peut donc 
                    la laisser commenté du moment qu'elle est sur 'yes'.
                </p>
                <img
                    src='../images/screenshots_for_books/securate_vps_28.png'  
                />
                <p>
                    Dans ce même fichier, on s'assure aussi que la propriété
                    PasswordAuthentication est sur 'no':
                </p>
                <img
                    src='../images/screenshots_for_books/securate_vps_29.png'  
                />
                <p>
                    On enregistre les modifications sur le fichier, puis on redémarre le service SSH:
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo service ssh restart</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <h2 class='etape'>Étape 5 : Connectez vous en SSH!</h2>
                <p>
                    On peut désormais connecter l'utilisateur en SSH avec la commande suivante; SSH vous demandera 
                    la passphrase de la clé privée:
                </p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh nomUtilisateur@adresseIPduServeur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>(Si le port SSH a été modifié, il faut penser à le définir après l'option -p)</p>
                <img
                    src='../images/screenshots_for_books/securate_vps_30.png'  
                />
                <legend>Un pas de plus vers la sécurisation de votre système Linux! ⭐</legend>
            </div>
            <div>

        </div>

        </div>





        <div class='sectionThematique'>
            Désactive l'accès SSH par le compte root
        </div>
        <div class='maincontainer_color' id='ssh_root'>
            <div class='p1_g'>
                <p>
                Empêcher le compte root de se connecter directement via SSH offre plusieurs avantages en termes de sécurité pour votre serveur. En outre, en désactivant l'accès direct au compte root, vous réduisez la surface d'attaque en limitant le nombre de tentatives d'authentification malveillantes. 
                </p>
                <p>En effet, les attaques par force brute visant spécifiquement le compte root sont courantes, et cette mesure de sécurité contribue à les dissuader.</p>
               

                <h2 class='etape'>Étape 1 : ouvrir le fichier de configuration SSH</h2>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>nano /etc/ssh/sshd_config</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <img
                    src='../images/screenshots_for_books/securate_vps_14.png'  
                />
               
            </div>
            <div class='p1_d'>
            <h2 class='etape'>Étape 2 : modifier la valeur de la propriété PermitRootLogin</h2>
            <p>Dans ce ficher de configuration, localiser la ligne 'PermitRootLogin' et changer la valeur en no pour empêcher l’accès SSH direct au compte root. 
            </p>
            <p>
                Enregistrer le fichier (control + X si vous utilisez nano).
            </p>
            <img
                    src='../images/screenshots_for_books/securate_vps_15.png'  
                />
                <legend>modification du fichier de configuration SSH</legend>
            </div>
        </div>

        <div class='maincontainer'>
            <div class='p1_g'>
                <h2 class='etape'>Étape 3 : redémarrer le service SSH</h2>
                <p>Pour que les modifications soient prises en compte, il faut redémarrer le service SSH avec la commande suivante :</p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo service ssh restart</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
            </div>

            <div class='p1_d'>
                <h2 class='etape'>Étape 4 : testez !</h2>
                <p>Vous pouvez maintenant vérifier que la connexion SSH avec le compte root n’est plus possible. </p>
                <p>Pour rappel, sans modification du port SSH (port 22 par défaut) il faut taper :</p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh root@adresseIpDuServeur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>Si le port SSH n’est pas le port par défaut, il faut le préciser avec l’option -p comme ceci :</p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh -p numeroPort root@adresseIpDuServeur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                <p>On constate que l’accès est dorénavant interdit :</p>
                <img
                    src='../images/screenshots_for_books/securate_vps_16.png'  
                />
                <legend>Un pas de plus vers la sécurisation de votre système Linux! ⭐</legend>
            </div>
        </div>


        <div class='sectionThematique'>
            Modifie le port SSH (au lieu du port 22)
        </div>

        <div class='maincontainer_color' id='ssh_port'>
            <div class='p1_g'>
                <p>Le port 22 c’est le port par défaut pour SSH. En changeant le port SSH on réduit le nombre de tentatives d’attaques automatisées vers son serveur. <br/>Le fichier de configuration se situe dans /etc/ssh/sshd_config. Ce fichier qui contrôle le comportement du service SSH (Secure Shell), ne peut être modifié que par root ou par un utilisateur du groupe sudo qui doit préciser son intention avec sudo.</p>
                <h2 class='question'>Que se passe t-il si je ne précise pas sudo ?</h2>
                <p class=''>Sans préciser sudo, on peut ouvrir le fichier mais on ne pourra pas le modifier.</p>

                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>nano /etc/ssh/sshd_config</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
               
            </div>
            <div class='p1_d'>
                <img
                    src='../images/screenshots_for_books/securate_vps_1.png'  
                />
                <legend>Message indiquant que le fichier ne peut être modifié</legend>
            </div>
        </div>

        <div class='maincontainer'>
        <div class='p1_d'>
            <img
                    src='../images/screenshots_for_books/securate_vps_4.png'  
                />
                <legend>Le fichier de configuration de ssh peut maintenant être modifié</legend>

            </div>
            <div class='p1_g'>
                <p>En revanche, en précisant sudo, j’obtiens le droit de modifier le fichier qui va me servir à modifier le port SSH :</p>
                <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo nano /etc/ssh/sshd_config</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>

            </div>
        </div>

        <div class='maincontainer_color'>
            <div class='p1_d'>
                <h2 class='question'>Il y a 65536 ports TCP et UDP disponibles sur un système informatique. Je choisis lequel ?</h2>
                <p>
                    <span>Rappelons déjà ce que sont les protocoles TCP et UDP.</span>
                    <ul class='enumeration'>
                        <li>Ports TCP : Ces ports sont associés aux connexions établies par le protocole TCP. Ils sont utilisés pour les services qui nécessitent une transmission fiable des données, comme le transfert de fichiers (FTP - File Transfer Protocol), les sites web (HTTP - Hypertext Transfer Protocol), le courrier électronique (SMTP - Simple Mail Transfer Protocol), etc. Les ports TCP sont numérotés de 0 à 65535.</li>
                        <li>Ports UDP : Ces ports sont associés aux datagrammes envoyés via le protocole UDP. Ils sont utilisés pour les services où une perte de quelques paquets de données est acceptable ou même attendue, comme les services de streaming média (UDP est souvent utilisé pour le streaming de vidéo et audio), les jeux en ligne, le service DNS (Domain Name System), etc. Comme pour les ports TCP, les ports UDP sont numérotés de 0 à 65535.</li>
                    </ul>
                </p>
            </div>
            <div class='p1_g'>
                <p>Pour choisir un autre port TCP pour la connexion SSH, on peut choisir n'importe quel numéro de port disponible dans la plage de ports non réservés, c'est-à-dire entre 1024 et 65535. </p>
                <ul class='enumeration'>
                    <li>Ports bien connus (0-1023) : Ces ports sont souvent appelés 'ports réservés' car ils sont associés aux services système couramment utilisés et sont généralement réservés aux services système bien connus tels que HTTP (port 80), HTTPS (port 443), SSH (port 22), FTP (port 21), etc.</li>
                    <li>Ports enregistrés (1024-49151) : Ces ports sont utilisés pour des applications ou des services spécifiques, mais ils ne sont pas aussi bien connus que les ports réservés. Certains logiciels utilisent des ports dans cette plage pour leurs communications réseau.</li>
                    <li>Ports dynamiques ou privés (49152-65535) : Ces ports sont souvent appelés 'ports éphémères' ou 'ports privés'. Ils sont utilisés par le système d'exploitation pour les connexions temporaires et sont généralement attribués dynamiquement par le système lorsqu'une application ou un service a besoin de communiquer sur le réseau.</li>
                </ul>

            </div>
            

        </div>

            <div class='maincontainer'>
                <div class='p1_g'>
                    <h2 class='question'>D’accord ! Et dans la pratique je m’y prends comment ?</h2>
                    <h2 class='etape'>Étape 1 : Vérifier la disponibilité du port</h2>
                    <p>Commençons par nous assurer que le port que l’on a choisi (dans notre exemple on veut remplacer le port 22 par le port 63333) n'est pas déjà utilisé par un autre service sur votre système. 
                    Pour faire cela on va utiliser la commande suivante :
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>netstat -tuln</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <p>Voici ce que signifie les options utilisées :</p>
                    <ul class='enumeration'>
                        <li>-t : affiche les connexions TCP</li>
                        <li>-u : affiche les connexions UDP</li>
                        <li>-l : affiche les connexions qui sont en mode écoute (c’est-à-dire les sockets en mode « LISTEN »).</li>
                        <li>-n : affiche les adresse IP et les numéros de port sous forme numérique</li>
                    </ul>
                    <p>
                    En combinant ces options, la commande netstat -tuln affiche toutes les connexions TCP et UDP en mode écoute, avec les adresses IP et les numéros de port au format numérique. Cela peut être utile pour identifier les services qui écoutent sur des ports spécifiques et vérifier les connexions réseau actives sur votre système.
                    Le port 63333 n’est pas utilisé on peut donc l’utiliser.
                    </p>
                </div>
                <div class='p1_d'>
                <img
                    src='../images/screenshots_for_books/securate_vps_5.png'  
                />
                <legend>résultat d'une commande netstat</legend>
                </div>
            </div>


            <div class='maincontainer_color'>
                <div class='p1_g'>
                <img
                    src='../images/screenshots_for_books/securate_vps_6.png'  
                />
                <legend>Modification du port dans le fichier de config SSH</legend>

                </div>
                <div class='p1_d'>
                    <h2 class='etape'>Étape 2 : modifier le numéro de port dans le fichier sshd_config</h2>
                    <p>On peut à présent lancer la commande suivante pour ouvrir le fichier
                        de configuration avec nano, décommenter la ligne sur le numéro de port
                        pour la modifier avec le nouveau numéro de port.
                    </p>
                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo nano /etc/ssh/sshd_config</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                </div>

            </div>


            <div class='maincontainer_startline'>
                <div class='p1_g'>
                    <h2 class='etape'>Étape 3 : Ouvrir le port dans le pare-feu</h2>
                    <p>Pour autoriser le trafic sur le nouveau port, et avec le firewall UFW, on tape la commande suivante :</p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo ufw allow 63333/tcp</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                </div>

                <div class='p1_d'>
                    <p>Ensuite, on vérifie que le port 63333 est correctemment ouvert avec:</p>
     
                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo status ufw</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_7.png'  
                    />
                    <legend>firewall UFW</legend>
                </div>
            </div>

            <div class='maincontainer_color_startline'>
                <div class='p1_g'>
                    <h2 class='etape'>Étape 4 : On redémarre le service SSH</h2>
                    <p>Pour que les modifications apportées sur le fichier de configuration SSH soient prises en compte, on doit redémarrer le service SSH. 
                        On peut utiliser la commande:
                    </p>
                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo service ssh restart</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                </div>

                <div class='p1_d'>
                    <p>Ensuite, à l'aide de netstat on vérifie que le port 22 n'est plus à l'écoute, et que le port 63333 est à l'écoute</p>
                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo netstat -tuln</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_8.png'  
                    />
                    <legend>Le port 63333 à remplacé le port 22 pour SSH</legend>
                </div>
            </div>

            <div class='maincontainer'>
            <div class='p1_g'>
                    <h2 class='etape'>Étape 5 : Connectons-nous en SSH sur le nouveau port</h2>
                    <p>Le moment est enfin venu de tester si la modification du port fonctionne correctemment.
                        Autrement dit on doit pouvoir se connecter en SSH sur le port 63333 et non plus sur le port 22.
                    </p>
                    <h2 class='question'>Et si j'essaye de me connecter encore au port 22 ?</h2>
                    <p>Essayons ça sans plus attendre; rappelons la commande pour se connecter en SSH à un serveur lorsque le port d'écoute est le port par défaut:</p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh userName@adresseIpDuServeur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_9.png'  
                    />
                    <p>
                        Sans surprise, on ne peut plus se connecter en SSH à notre VPS via le port 22. 
                    </p>
                    <p>
                        Connectons nous maintenant sur le port 63333.
                    </p>
                    <p>
                        Pour ce faire, il faut préciser dorénavant le numéro de port car ce n’est plus le port par défaut. La commande est légèrement différente, il
                        faudra systématiquement préciser le port d'écoute avec l'option -p :
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>ssh -p numeroDePort userName@adresseIpDuServeur</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_10.png'  
                    />



                </div>

                <div class='p1_d'>
                    <p>On saisit le mot de passe de l'utilisateur, et SSH nous connecte alors au port 63333 !</p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_11.png'  
                    />
                    <legend>On est connecté !</legend>
                </div>


            </div>

            <div class='maincontainer_color'>
                <div class='p1_g'>
                    <h2 class='etape'>Étape 6 : Fermons le port 22</h2>
                    <p>
                        Souvenons-nous qu'utiliser uniquement les ports dont on a besoin contribue à réduire 
                        la surface d'attaque. Alors maintenant qu’on a déporté la connexion SSH vers le port 63333, 
                        on peut fermer le port 22 qui ne nous est plus d'aucune utilité.
                    </p>
                    <p>
                        Avec UFW, on va taper cette commande:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo ufw delete allow 22/tcp</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_12.png'  
                    />
                    
                </div>
                <div class='p1_d'>
                    <p>Une dernière petite vérification:</p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo ufw status</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <div>Le port 22 n'apparait plus. On a donc correctemment
                        remplacé le port de connexion SSH par défaut par le port 
                        63333
                    </div>
                    <img
                        src='../images/screenshots_for_books/securate_vps_13.png'  
                    />
                    <legend>Un pas de plus dans la sécurisation de votre système Linux! ⭐ </legend>
                </div>


            </div>

            <div class='sectionThematique' id='fail2ban'>
                Utilise Fail2ban
            </div>
            <div class='sectionThematique' id='miseAjour'>
                Automatise les mises à jour (unattended-upgrades)
            </div>
            <div class='maincontainer'>
                <div class='p1_g'>
                    <p>Lorsque les mises à jour ne se déclenchent pas automatiquement sur un système Linux,
                        on a coutume de taper manuellement cette commande:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo apt update &&  apt upgrade</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <p>Rappelons ce que fait précisemment cette commande lorsqu'elle est lancée.</p>
                    <p>
                        <ul class='enumeration'>
                            <li>
                                apt update: Cette partie de la commande met à jour les informations sur les paquets 
                                disponibles depuis les référentiels de logiciels configurés sur votre système. <br></br>
                                En d'autres termes, cela met à jour la liste des paquets disponibles et leurs versions 
                                actuelles, <span class='texte_important'>mais n'installe pas réellement de nouvelles versions.</span>
                            </li>

                            <li>
                                &&: C'est un opérateur de contrôle de flux qui signifie 'et'. Dans ce contexte, cela signifie 
                                que <span class='texte_important'>la commande suivante (apt upgrade) ne sera exécutée que si la commande précédente (apt update)</span> 
                                s'est terminée avec succès.
                            </li>
                            <li>
                                apt upgrade: Cette partie de la commande met à jour les paquets logiciels installés sur votre système 
                                vers leurs dernières versions disponibles. <br></br>
                                Cela installe effectivement les <span class='texte_important'>nouvelles versions des paquets </span>
                                si elles sont disponibles dans les référentiels de logiciels configurés sur votre système.

                            </li>
                        </ul>

                    </p>



                </div>
                <div class='p1_d'>
                <p>
                        Toutefois, le faire manuellement présente un certains nombres d'inconvénients, notamment
                        le fait de ne pas mettre à jour son système pendant un certain temps (congès ou simple oubli).
                        Cette éventualité peut devenir critique car lorsqu'une faille de sécurité sur un paquet est 
                        détectée, le correctif doit être appliqué sans tarder, et cela pour ne pas compromettre la
                        sécurité du système.
                    </p>
                    <p>
                        Pour automatiser les mises à jour, nous allons voir comment installer <span class='mot_cles'>unattended-upgrades</span>,
                        un paquet logiciel pour Debian et Ubuntu.
                    </p>
                    <h2 class='etape'>
                        Étape 1 : Installation des paquets
                    </h2>
                    <p>
                        Commençons par installer le paquet unattended-upgrades en sudo avec apt via la commande :
                    </p>
                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo apt install unattended-upgrades</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_31.png'  
                    />
                    <p>
                        Conjointement au paquet unattended-upgrades, l'installation d'un second paquet, update-notifier-common 
                        est souvent recommandée pour permettre à l'utilisateur de recevoir des notifications de mises à jour et 
                        de rester informé de l'état de son système.<br></br>
                        
                    </p>
                </div>

            </div>

            <div class='maincontainer_color'>
                <div class='p1_g'>
                    <p>
                        On éxécute la commande suivante:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo apt install update-notifier-common</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_32.png'  
                    />
                    <p>
                        Généralement le paquet <span class='texte_important'>update-notifier-common est installé par défaut </span>avec les installations standard d'Ubuntu. 
                        Il fait partie des composants système qui gèrent les mises à jour logicielles et notifient l'utilisateur 
                        lorsqu'il y a des mises à jour disponibles.
                    </p>
                    <h2 class='etape'>Étape 2 : Paramètres du paquet unattended-upgrades</h2>
                    <p>
                        Lors de l’installation du paquet unattended-upgrades¬¬, le gestionnaire de paquets 'apt' crée dans 
                        le répertoire /etc/apt/apt.conf.d un fichier <span class='texte_important'>50unattended-upgrades</span>.
                    </p>
                    <p>
                        Le fichier 50unattended-upgrades est utilisé pour configurer les paramètres de unattended-upgrades.
                    </p>
                </div>

                <div class='p1_d'>
                    <p>
                        En modifiant ce fichier, on va pouvoir configurer différentes options pour contrôler le comportement de 
                        unattended-upgrades, telles que les sources de mises à jour à utiliser, les types de paquets à mettre à jour 
                        automatiquement etc.
                    </p>
                    <p>
                        Commençons par jeter un œil dans ce répertoire etc/apt/apt.conf.d ce qu’on peut faire avec la commande :
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>cd /etc/apt/apt.conf.d && ls</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_33.png'  
                    />
                </div>

            </div>

            <div class='maincontainer'>
                <div class='p1_g'>
                    <h2 class='question'>C’est quoi ce numéro 50 devant le nom du fichier ?</h2>
                    <p>
                        Le préfixe 50 dans le nom du fichier indique <span class='texte_important'>l'ordre de priorité du fichier</span> de configuration par rapport 
                        aux autres fichiers dans le répertoire. 
                    </p>
                    <p>
                        Les fichiers de configuration dans /etc/apt/apt.conf.d/ sont lus dans l'ordre numérique, et les paramètres dans les fichiers avec des numéros plus bas sont écrasés par ceux des fichiers avec des numéros plus élevés. 
                    </p>
                    <p>
                        Ainsi, 50unattended-upgrades est <span class='texte_important'>lu après les fichiers portant des numéros inférieurs</span> mais avant ceux portant des numéros plus élevés.
                    </p>
                    <p>
                        Découvrons sans plus tarder le contenu de ce fichier de configuration:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo nano /etc/apt/apt.conf.d/50unattended-upgrades</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <p>
                        Dans la configuration par défaut, ces 4 lignes sont activées.
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_34.png'  
                    />

                </div>
                <div class='p1_d'>
                    <p>
                    <p>Voyons ce que ces 4 lignes signifient :</p>
                        <ul class='enumeration'>
                            <li>
                                {'${distro_id}:${distro_codename}'}: Cette ligne autorise les mises à niveau automatiques à partir de <span class='texte_important'>l'archive principale </span>de votre distribution. <br></br>
                                {'${distro_id}'} fait référence à l'identifiant de distribution (par exemple, 'ubuntu'), et {'${distro_codename}'} fait référence au nom de code de la version de votre distribution (par exemple, 'Jammy Jellyfish' pour Ubuntu 22.04 sortie en avril 2022).
                            </li>
                            <li>
                                {'${distro_id}:${distro_codename}-security'}: Cette ligne autorise les mises à niveau automatiques à partir de <span class='texte_important'>l'archive de sécurité</span> de votre distribution. <br></br>
                                Cela signifie que les mises à jour de sécurité seront automatiquement installées.
                            </li>
                            <li>
                                {'${distro_id}ESMApps:${distro_codename}-apps-security'}: Cette ligne autorise les mises à niveau automatiques à partir de l'archive de sécurité des applications pour les versions 
                                bénéficiant d'un support étendu (ESM). <br></br>
                                Ceci est <span class='texte_important'>spécifique à Ubuntu ESM (Extended Security Maintenance)</span>, une offre de support étendu pour certaines 
                                versions d'Ubuntu.

                            </li>
                            <li>
                                {'${distro_id}ESM:${distro_codename}-infra-security'}: Cette ligne autorise les mises à niveau automatiques à partir de l'archive de sécurité de l'infrastructure pour les versions 
                                bénéficiant d'un support étendu <span class='texte_important'>(ESM)</span>, comme décrit ci-dessus.
                            </li>
                        </ul>

                    </p>

                </div>

            </div>

            <div class='maincontainer_color'>
                <div class='p1_d'>
                    <p>Intéressons-nous à présent à cette ligne: </p>
                    <p> {'${distro_id}:${distro_codename}-updates'} </p>
                    <br></br>
                    <img
                        src='../images/screenshots_for_books/securate_vps_35.png'  
                    />
                    <ul class='enumeration'>
                        <li>
                            {'${distro_id}:${distro_codename}-updates'}: dans la configuration par défaut cette ligne est commentée. <br></br>
                            Cela signifie que <span class='texte_important_rs'>l’archive des mises à jour régulières n'est pas activée</span> pour les mises à jour automatiques par défaut. 
                            Cela inclut les mises à jour de logiciels <span class='texte_important'>qui ne sont pas directement liées à des failles de sécurité</span>, mais qui fournissent des correctifs de bogues, des améliorations de fonctionnalités ou de nouvelles versions de logiciels.
                        </li>
                    </ul>


                </div>
                <div class='p1_g'>
                    <h2 class='question'>
                        Archive de sécurité vs archive de mises à jour régulières ?
                    </h2>
                    <p>
                        Cette distinction est importante pour comprendre le fichier de configuration du paquet unattended-upgrades. Voyons leur rôle respectif :
                    </p>
                    <p>
                        <h3 class='titre_important'>Archive de sécurité :</h3>
                        <ul class='enumeration'>
                            <li>
                                L'archive de sécurité contient des mises à jour spécifiquement conçues pour <span class='texte_important'>corriger les vulnérabilités de sécurité</span> dans les logiciels.
                            </li>
                            <li>
                                Ces mises à jour comprennent des <span class='texte_important'>correctifs </span>de sécurité pour les paquets qui ont été identifiés comme étant vulnérables à des failles de sécurité connues.
                            </li>
                            <li>
                                <span class='texte_important'>Les mises à jour de sécurité sont cruciales</span> pour maintenir la sécurité de votre système en patchant les failles de sécurité connues qui pourraient être exploitées par des attaquants.
                            </li>
                        </ul>

                    </p>
                    <p>
                        <h3 class='titre_important'>Archive de mises à jour régulières :</h3>
                        <ul class='enumeration'>
                            <li>
                                L'archive de mises à jour régulières contient des mises à jour pour les logiciels <span class='texte_important'>qui ne sont pas liées à des failles de sécurité</span> spécifiques.
                            </li>
                            <li>
                                Ces mises à jour incluent des correctifs de bogues, des améliorations de fonctionnalités et parfois de <span class='texte_important'>nouvelles versions</span> de logiciels.
                            </li>
                            <li>
                                Les mises à jour régulières peuvent inclure des nouvelles fonctionnalités, des <span class='texte_important'>améliorations de performance</span> et des corrections de bogues 
                                qui ne sont pas directement liées à la sécurité, mais qui peuvent améliorer l'expérience utilisateur et la <span class='texte_important'>stabilité du système</span>.
                            </li>
                        </ul>


                    </p>


                </div>

            </div>

            <div class='maincontainer'>
                <div class='p1_g'>
                    <h2 class='question'>
                        Est-ce que je dois activer l’archive des mises à jour régulières ?
                    </h2>
                    <p>
                        En décommentant la ligne {'${distro_id}:${distro_codename}-updates'} dans la configuration de unattended-upgrades, vous autoriserez les mises à niveau automatiques
                         <span class='texte_important'>à partir de l'archive de mises à jour régulières</span> 
                         de votre distribution Ubuntu.
                    </p>
                    <p>
                        Voici quelques points à considérer pour savoir s’il faut ou non l’activer:
                    </p>
                    <ul class='enumeration'>
                        <li>
                            Tolérance au risque : Si vous êtes prêt à prendre le <span class='texte_important_rs'>risque potentiel de perturbation</span> de vos sites web en échange de maintenir
                             votre système à jour avec les dernières fonctionnalités et corrections de bogues, alors vous pouvez envisager d'activer 
                             les mises à jour régulières.
                        </li>
                        <li>
                            Critère de disponibilité : Si vos sites web en production sont critiques et <span class='texte_important_rs'>ne peuvent pas se permettre d'être indisponibles</span>, 
                            même pendant de courtes périodes, vous pouvez choisir de désactiver les mises à jour régulières automatiques pour éviter les perturbations imprévues.
                        </li>
                        <li>
                            Capacité de gestion : Si vous avez les ressources et les compétences nécessaires pour gérer manuellement les mises à jour et les redémarrages de votre système, 
                            vous pouvez préférer désactiver les mises à jour automatiques et gérer les mises à jour de manière proactive.
                        </li>
                        <li>
                            Exigences de sécurité: Si la sécurité est une préoccupation majeure et que vous souhaitez vous assurer que votre système est toujours à jour avec les derniers correctifs de sécurité, vous devriez envisager de maintenir 
                            l'archive de sécurité activée tout en désactivant les mises à jour régulières automatiques.
                        </li>
                    </ul>

                </div>
                <div class='p1_d'>
                    <p>
                        Poursuivons notre découverte du fichier de configuration du paquet unattended-upgrades et voyons comment l'utilisateur
                        peut être informé du bon ou mauvais déroulement des opérations de mises à jour automatiques.
                    </p>
                    <h3 class='sous_rubrique'>Notification par mail :</h3>
                    <img
                        src='../images/screenshots_for_books/securate_vps_37.png'  
                    />
                    <p>
                        En décommentant le paramètre Unattended-Upgrade::Mail on demande à unattended-upgrades 
                        d'envoyer un mail de notification à l'adresse mail choisie. Il faut toutefois préalablement
                        avoir configuré un serveur SMTP (voir guide de survie).
                    </p>
                    <p>
                        Le paramètre Unattended-Upgrade::MailReport offre 3 possibilités :
                    </p>
                    <ul class='enumeration'>
                        <li>
                            always : les rapports par courrier électronique seront toujours envoyés, qu'il y ait eu des modifications ou non.
                        </li>
                        <li>
                            only-on-error : Dans ce cas, les rapports par courrier électronique ne seront envoyés <span class='texte_important'>que s'il y a eu une/des erreur(s)</span> lors de la mise à jour.
                        </li>
                        <li>
                            on-change : les rapports par courrier électronique seront envoyés <span class='texte_important'>uniquement en cas de changement </span>dans la configuration ou les paquets.
                        </li>
                    </ul>

                </div>

            </div>

            <div class='maincontainer_color'>
                <div class='p1_g'>
                    <h3 class='sous_rubrique'>Suppression automatique des paquets du noyau inutilisés:</h3>
                    <p>
                        Lorsque ce paramètre est défini sur 'true', le système supprimera automatiquement les paquets du noyau (telles que les images du noyau, 
                        les en-têtes du noyau et les outils verrouillés par version du noyau) qui ont été installés automatiquement mais qui ne sont plus nécessaires. 
                    </p>
                    <p>
                        Ceci est généralement fait pour libérer de l'espace disque et maintenir le système propre en supprimant les éléments inutilisés.
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_38.png'  
                    />
                    <h3 class='sous_rubrique'>Suppression automatique des dépendances nouvellement inutilisées:</h3>
                    <p>
                        Lorsque ce paramètre est défini sur 'true', le système vérifiera après une mise à niveau si des <span class='texte_important'>dépendances qui étaient nécessaires 
                        avant </span>la mise à niveau ne sont plus utilisées. 
                    </p>
                    <p>
                        Si tel est le cas, ces dépendances seront automatiquement supprimées pour libérer de l'espace disque et maintenir le système propre en supprimant les éléments inutilisés. 
                    </p>
                    <p>
                        Cela contribue également à la gestion efficace de l'espace disque et à la maintenance du système.
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_39.png'  
                    />
                </div>
                <div class='p1_d'>
                    <h3 class='sous_rubrique'>Suppression automatique des paquets inutilisés après une mise à niveau:</h3>
                    <p>
                        Ce paramètre contrôle si <span class='texte_important'>les paquets qui ne sont plus utilisés</span> doivent être automatiquement supprimés après une mise à niveau. 
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_40.png'  
                    />
                    <br></br>
                    <h2 class='question'>
                        Quelle différence entre Remove-New-Unused-Dependencies et Remove-Unused-Dependencies ?
                    </h2>
                    <p>
                        le premier paramètre est plus spécifique aux dépendances nouvellement inutilisées après une mise à niveau, tandis que le second paramètre concerne tous les paquets inutilisés après une mise à niveau.
                    </p>
                    <h3 class='sous_rubrique'>Activation du redémarrage automatique</h3>
                    <p>
                        La décision d'activer ou de désactiver le redémarrage automatique après les mises à niveau dépend principalement de la <span class='texte_important_rs'>tolérance aux interruptions de service</span> et des procédures 
                        de gestion des mises à niveau sur votre infrastructure.
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_41.png'  
                    />

                </div>

            </div>

            <div class='maincontainer'>
                <div class='p1_g'>
                    <ul class='enumeration'>
                        <li>
                            Impact sur les services en cours d'exécution : Si vous activez le redémarrage automatique, le système redémarrera sans confirmation de 
                            l'utilisateur après les mises à niveau, ce qui peut entraîner une interruption temporaire des services en cours d'exécution. <br></br>
                            Si vos sites web sont sensibles aux interruptions ou si vous avez des exigences de disponibilité élevées, vous pourriez préférer désactiver cette fonctionnalité pour avoir un contrôle manuel sur les redémarrages.
                        </li>
                        <li>
                            Automatisation et efficacité opérationnelle : D'un autre côté, activer le redémarrage automatique peut simplifier le processus 
                            de gestion des mises à niveau et garantir que les correctifs critiques sont appliqués rapidement sans nécessiter une intervention manuelle.
                        </li>
                    </ul>
                    <h3 class='sous_rubrique'>Heure du redémarrage automatique :</h3>
                    <p>
                        Ce paramètre contrôle l'heure à laquelle le redémarrage automatique doit avoir lieu si le système détecte qu'un redémarrage est nécessaire après une mise à niveau, et que le redémarrage automatique est activé.
                    </p>
                    <p>
                        Dans notre configuration, on prévoit un redémarrage automatique à 2H du matin.
                    </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_42.png'  
                    />

                </div>
                <div class='p1_d'>
                    <h2 class='etape'>Étape 3 : reconfiguration du paquet </h2>
                    <p>Pour faire cela, on utilise la commande suivante :</p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo dpkg-reconfigure -plow unattended-upgrades</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <p>
                        Voyons en détail ce que fait cette commande:
                    </p>
                    <ul class='enumeration'>
                        <li>
                            sudo: cela exécute la commande avec des privilèges de superutilisateur, ce qui est souvent nécessaire pour effectuer des opérations de configuration système.
                        </li>
                        <li>
                            dpkg-reconfigure: c'est une commande qui permet de reconfigurer un package déjà installé sur le système. Elle lance un script interactif qui vous guide à travers le processus de configuration du package.
                        </li>
                        <li>
                            -p: indique à dpkg-reconfigure d'afficher la priorité par défaut pour chaque question de configuration.
                        </li>
                        <li>
                            low : spécifie que seules les questions de configuration ayant une priorité basse ou plus élevée seront posées.
                        </li>
                    </ul>
                    <p>Une fenêtre s’ouvre, choisir 'Yes' :</p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_43.png'  
                    />
                    <p>
                    Cette commande va créer un fichier '20auto-upgrades' (s’il n’existe pas déjà) dans /etc/apt/apt.conf.d

                    </p>


                </div>
            </div>

            <div class='maincontainer_color'>
                <div class='p1_g'>
                    <img
                            src='../images/screenshots_for_books/securate_vps_44.png'  
                    />
                    <p>
                        Voyons ce que fait ce fichier :
                    </p>
                    <ul class='enumeration'>
                        <li>
                            APT::Periodic::Update-Package-Lists '1'; : Cette ligne indique à APT de mettre à jour la liste des paquets disponibles à intervalles réguliers. <br></br>
                            En définissant la valeur sur '1', cela active la mise à jour automatique de la liste des paquets. 
                            Cela garantit que votre système dispose des informations les plus récentes sur les paquets disponibles dans les dépôts.
                        </li>
                        <li>
                            APT::Periodic::Unattended-Upgrade '1'; : Cette ligne indique à APT d'effectuer les mises à jour automatiques des paquets sans surveillance. <br></br>
                            En définissant la valeur sur '1', cela active la mise à niveau automatique des paquets sans nécessiter d'intervention de l'utilisateur. Cela permet à votre système de recevoir et d'installer automatiquement les mises à jour de sécurité et les correctifs.
                        </li>
                    </ul>
                    <h2 class='etape'>Étape 4: Simuler l’exécution des mises à jour automatique (test)</h2>
                    <p>
                        Dernière étape, on va s’assurer que tout fonctionne correctement sans avoir à attendre qu’il y ait vraiment des mises à jour de sécurité, ce qu’on va faire grâce à la commande :
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo unattended-upgrades --dry-run --debug</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>

                </div>
                <div class='p1_d'>

                    <p>Voyons ce que fait la commande en detail :</p>
                    <ul class='enumeration'>
                        <li>
                            --dry-run : Cette option simule l'exécution des mises à jour <span class='texte_important_rs'>sans les appliquer effectivement</span>. Cela signifie que la commande affichera ce qui serait fait, mais n'effectuera aucune modification réelle sur le système. C'est utile pour tester les mises à jour avant de les appliquer réellement, afin de voir quels paquets seraient mis à jour et de vérifier s'il y a des erreurs potentielles.
                        </li>
                        <li>
                            --debug : Cette option <span class='texte_important'>active le mode de débogage,</span> ce qui signifie que la commande affichera des informations supplémentaires sur ce qu'elle fait en arrière-plan. 
                            Cela peut inclure des détails sur les paquets qui sont examinés pour les mises à jour, les actions qui sont planifiées et tout problème rencontré lors de la simulation des mises à jour.
                        </li>
                    </ul>
                    <p>
                        Si tout se passe bien, vous devriez obtenir quelque chose comme ça :
                    </p>
                    <img
                            src='../images/screenshots_for_books/securate_vps_45.png'  
                    />
                    <legend>Un pas de plus vers la sécurisation de votre système Linux! ⭐</legend>
                </div>

            </div>







            <div class='sectionThematique' id='ipv6_off'>
                Désactive ipv6
            </div>
            <div class='maincontainer'>
                <div class='p1_g'>
                    <p>
                    IPv6 (Internet Protocol version 6) est la version la plus récente du protocole Internet, succédant à IPv4. IPv6 a été créé pour résoudre le problème de l'épuisement des adresses IPv4 disponibles et pour répondre aux besoins croissants en connectivité à l'ère d'Internet des objets (IoT), 
                    des appareils mobiles et de l'expansion continue d'Internet.
                    </p>
                    <h2 class='question'>Pourquoi désactiver ipv6 ?</h2>
                    <p>
                    Pour commencer, la recommandation de désactiver IPv6 pour des raisons de sécurité est quelque peu controversée et dépend des circonstances spécifiques. 
                    Toutefois, en désactivant IPv6, vous réduisez la surface d'attaque potentielle de votre serveur. Moins de services activés signifie théoriquement moins de vecteurs d'attaque.
                    </p>
                    <h2 class='etape'>Étape 1 : Le fichier des paramètres du noyau Linux</h2>
                    <p>
                        <ul class='enumeration'>
                            <li>Gestion de la mémoire : Le noyau Linux alloue et libère la mémoire système en fonction des besoins des processus en cours d'exécution.</li>
                            <li>Gestion des processus : Le noyau Linux est responsable de la création, de l'exécution, de la terminaison et de la gestion des processus sur le système.</li>
                            <li>Gestion des périphériques : Le noyau Linux gère les périphériques matériels connectés à l'ordinateur, y compris les disques, les périphériques réseau, les périphériques USB, etc.</li>
                            <li>Gestion des fichiers : Le noyau Linux fournit un système de fichiers qui permet aux utilisateurs et aux applications d'accéder, de créer, de modifier et de supprimer des fichiers sur le système de stockage.</li>
                            <li>Gestion du réseau : Le noyau Linux prend en charge la connectivité réseau en fournissant des pilotes de périphériques réseau, des protocoles de communication réseau et des fonctionnalités de routage.</li>
                            <li>Sécurité : Le noyau Linux met en œuvre des mécanismes de sécurité pour protéger le système contre les accès non autorisés et les vulnérabilités.</li>
                        </ul>
                    </p>
                    <p>Le noyau Linux est un logiciel open source et est développé de manière collaborative par une large communauté de développeurs à travers le monde.</p>
                        <p>Il est utilisé comme base pour de nombreuses distributions Linux, telles que Ubuntu, Debian, Fedora, CentOS, et bien d'autres.</p>
                        <p>Pour désactiver ipv6, on tape la commande suivante pour modifier les paramètres du noyau</p>

                        <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo nano /etc/sysctl.conf</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                        
                </div>
                <div class='p1_d'>
                <p>Il faut ensuite ajouter ces 2 lignes pour désactiver ipv6:</p>
                        <img
                        src='../images/screenshots_for_books/securate_vps_17.png'  
                    />

                    <p>Il ne reste plus qu'à enregistrer les modifications et taper la commande
                        suivante pour mettre à jour les paramètres du noyau:
                    </p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sudo sysctl -p</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_18.png'  
                    />
                    <p>Enfin, vérifions avec la commande suivante que ipv6 est bien désactivé:</p>

                    <span class='intituleTerminal'>Terminal</span>
                <p class='consoleview'>
                    <p class='ligneDeCommande'>
                        <span>sysctl -a | grep disable_ipv6</span>
                        <button class='copy-button'>copier</button>
                    </p>
                </p>
                    <img
                        src='../images/screenshots_for_books/securate_vps_19.png'  
                    />
                    <legend>Un pas de plus vers la sécurisation de votre système Linux! ⭐</legend>
                    <h2 class='question'>C'est quoi eth0? eth1? lo?</h2>
                    <p>
                        <ul class='enumeration'>
                           <li>
                                h0 : Cette interface représente généralement la première interface Ethernet sur votre système. Lorsque vous connectez un câble Ethernet à votre ordinateur, 
                                il est généralement associé à l'interface eth0. Cette interface est souvent utilisée pour la connectivité réseau principale, telle que la connexion à Internet 
                                ou à un réseau local.
                            </li>
                            <li>
                                eth1 : Si votre système dispose de plusieurs cartes réseau, la deuxième carte pourrait être associée à l'interface eth1. 
                                Dans un environnement serveur ou dans des configurations réseau plus complexes, vous pouvez utiliser eth1 pour connecter votre système à un réseau distinct. 
                                Par exemple, vous pourriez avoir eth0 connecté à Internet et eth1 connecté à un réseau local privé.
                            </li>
                            <li>
                                lo : L'interface lo, ou loopback, est une interface spéciale qui permet aux applications sur votre système de communiquer avec elles-mêmes. 
                                Elle est utilisée pour les communications internes sur le système. Par exemple, lorsque vous accédez à localhost, vous utilisez l'interface lo 
                                pour communiquer avec le serveur web ou d'autres services s'exécutant sur votre propre système.
                            </li>
                        </ul>
                    </p>


                    
                </div>



            </div>
            



    </div> */}

            <BackToTop/>
            <Footer/>
        </section>
    );
}

export default ArticleBook;







{/* <div class='maincontainer'>
<div class='p1_g'>

</div>
<div class='p1_d'>

</div>

</div> */}



        {/* <div style={{width:'100%',height:'0', paddingBottom:'10%', position:'relative', background:'#03031A'}}><iframe src='https://giphy.com/embed/3ohs4oWkzyVeVgTwKQ' width='100%' height='100%' style={{position:'absolute', background:'black !important'}} frameBorder='0' class='giphy-embed' ></iframe></div><p><a href='https://giphy.com/gifs/3ohs4oWkzyVeVgTwKQ'></a></p> */}