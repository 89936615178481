import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const datas = {
  'rubrique1': {
    text: 'Données',
    q2:'Comment sont gérées les données de paiement ?',
    r2:'Aucune données bancaire n\'est enregistrée en base de données. Ce service est pris en charge par Stripe, la meilleure solution de paiement pour le e-commerce.',
    q1:'Comment sont gérés les mots de passe ?',
    r1:'Les mots de passe sont gérés de manière sécurisée en utilisant l\'algorithme de hachage bcrypt. Bcrypt est une méthode robuste de hachage de mots de passe qui garantit une sécurité optimale en stockant les mots de passe de manière sécurisée et en empêchant leur récupération facile en cas de compromission des données. Cela garantit la confidentialité des informations sensibles des utilisateurs et renforce la sécurité de votre site web.',
    icon: 'images/donnees-personnelles.png'
  },
  'rubrique2': {
    text: 'Serveurs',
    q1:'Quelle polique en matière de sécurité ?',
    r1:'Mes serveurs sont sécurisés par des pare-feu avancés, des protocoles de cryptage, des mises à jour régulières et des politiques de sécurité strictes que j\'implémente. Je veille à maintenir un environnement sécurisé en surveillant de manière proactive les éventuelles menaces, assurant ainsi la protection constante de vos données.',
    q2:'Comment sont gérés les accès aux serveurs ?',
    r2:'Pour gérer les accès aux serveurs j\'utilise des méthodes d\'authentification robustes telles que l\'authentification à deux facteurs, l\'utilisation de clés SSH pour les connexions sécurisées, et la gestion stricte des identifiants et des privilèges d\'accès. Des audits réguliers sont effectués pour surveiller et contrôler les accès, et les politiques de sécurité sont appliquées pour limiter l\'accès uniquement aux utilisateurs autorisés.',
    icon: 'images/serveur.png'
  },
  'rubrique3': {
    text: 'Process',
    q1:'J\'ai pris contact avec vous, c\'est quoi la suite ?',
    r1:'Le plus important c\'est que vous ayez bien réfléchi à votre projet pour éviter des allers-retours et des frais inutiles. On discute donc de votre projet sur la base de votre cahier des charges. Ensuite, je vous soumettrai une proposition détaillée incluant la portée du projet, le calendrier et le prix de la prestation. Une fois les termes convenus, je concevrai et développerai votre application. Vous aurez bien évidemment l\'occasion de donner votre avis à chaque étape. Enfin, je vous livrerai une version finale de l\'application. Je me charge de l\'hébergement.',
    q2:'',
    r2:'',
    icon: 'images/process.png'
  },
  'rubrique4': {
    text: 'Vocable',
    q1:'C\'est quoi une API ?',
    r1:'Pour faire simple, une API c\'est un script qui définit comment les composants d\'un programme peuvent interagir et échanger des données de manière cohérente et sécurisée. Par exemple, une API peut permettre à un site web d\'accéder aux données d\'une application ou d\'un service tiers pour les afficher ou les manipuler.',
    q2:'C\'est quoi React ? Angular ?',
    r2:'React et Angular sont des cadres de développement web utilisés pour créer des applications web interactives et dynamiques. React, développé par Facebook, se concentre sur la construction d\'interfaces utilisateur réactives en utilisant des composants réutilisables. Angular, développé par Google, offre une structure robuste pour le développement d\'applications web complexes en utilisant le langage TypeScript. Ces outils sont largement adoptés dans l\'industrie pour leur capacité à accélérer le processus de développement et à créer des expériences utilisateur modernes et performantes.',
    icon: 'images/techno.png'
  }
};

const slidein = keyframes`
  0% {
    top: -400px;
    opacity: 0;
  }
  
  100% {
    opacity: 1;
    top: 0px;
  }
`;

const slideout = keyframes`
  0% {
    top: 0;
    opacity: 1;
  }
  
  100% {
    top: -400px;
    opacity: 0;
  }
`;

const FaqCarousel = () => {
  const [selectedSkill, setSelectedSkill] = useState('rubrique1');

  return (
    <div>
    <TitleSection>
      <h2 className='titreFAQ'>Foire aux questions</h2>
    </TitleSection>
    <Scene>
      
        
      <LeftZone>
        <List>
          {Object.entries(datas).map(([key, { text, icon }]) => (
            <ListItem className='sm:pl-9' key={key} selected={selectedSkill === key} onClick={() => setSelectedSkill(key)} color={getColor(key)}>
              <Icon src={icon} alt={key} />
              <Text>{text}</Text>
            </ListItem>
          ))}
        </List>
      </LeftZone>


      <RightZone>
        {Object.entries(datas).map(([key, { text, q1, r1, q2, r2}]) => (
          <Content key={key} visible={selectedSkill === key}>
            <p className='text-[16px] sm:text-[18px] py-5 text-left text-orange-300'>{q1}</p>
            <p className='text-[16px] sm:text-[18px] text-white'> {r1}</p>
            <p className='text-[16px] sm:text-[18px] py-5 text-orange-300'>{q2}</p>
            <p className='text-[16px] sm:text-[18px] text-left text-white'> {r2}</p>
          </Content>
        ))}
      </RightZone>
    </Scene>
    </div>
  );
};

export default FaqCarousel;

const TitleSection = styled.div`

background-color:#26262E;
`;

const Scene = styled.div`
  display: flex;

  align-items: center;
  justify-content: left;
  width: auto;
  height: 600px;
  margin: auto;
  padding: 10px;
background-color:#26262E;

  overflow: hidden;
 
  @media (max-width: 768px) {
    height: 150vh;
    max-height:980px;
    display:flex;
    flex-direction:column;
    padding: 5px;
  }






  `;

const LeftZone = styled.div`
  width:20%;
background-color:#26262E;
color:white;

  
  @media (max-width: 768px) {
    display:flex !important;
    width:100%;
  }

`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  @media (max-width: 768px) {
    font-size:16px !important;
    padding-top:20px;
    display:flex !important;
    justify-content:center;
    align-items:center;
    width:100%;
  }
`;

const ListItem = styled.li`
  font-size: 18px;
  margin-bottom: 14px;
  cursor: pointer;
  border-right: 4px solid transparent;
  ${({ selected, color }) => css`
    opacity: ${selected ? 1 : 0.5}; /* Opacité réduite pour les éléments non sélectionnés */
    border-right-color: ${selected ? color : 'transparent'};
    ${Text} {
      color: ${selected ? color : 'inherit'};
      font-weight: ${selected ? 600 : 'normal'}; /* Poids de la police plus gras pour les éléments sélectionnés */
    }
    ${Icon} {
      opacity: ${selected ? 1 : 0};
    }
  `}
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display:flex !important;
    flex-direction:column !important;
    justify-content:left !important;
    align-items:left !important;
    padding: 0px 10px;
    border-right: 0px;
    border-bottom: 4px solid transparent;
    ${({ selected, color }) => css`
    opacity: ${selected ? 1 : 0.5}; /* Opacité réduite pour les éléments non sélectionnés */
    border-bottom-color: ${selected ? color : 'transparent'};
    ${Text} {
      color: ${selected ? color : 'inherit'};
      font-weight: ${selected ? 600 : 100}; /* Poids de la police plus gras pour les éléments sélectionnés */
    }
    ${Icon} {
      opacity: ${selected ? 1 : 0};
    }
  `}
    
  }
`;


const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  opacity: 0.2;
  transition: opacity 0.3s ease;



  @media (max-width: 768px) {
    display:flex;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }

`;

const Text = styled.span`
@media (max-width: 768px) {
  display:flex;

}



`;


const RightZone = styled.div`


  width:80%;
  padding: 20px;

  @media (max-width: 768px) {
    display:flex;
    flex-direction:row !important;
    padding: 5px;
  }

`;

const Content = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  ${({ visible }) => visible ? css`
    animation: ${css`${slidein}`} 0.75s ease-out;
  ` : css`
    animation: ${css`${slideout}`} 0.75s ease-out;
  `}
  position: relative;



  h1 {
    color: ${({ rubrique }) => getColor(rubrique)};
    text-transform: capitalize;
    font-weight:600;
  }

  p {
    max-width: 100%;
    text-align: left;
  }
  @media (max-width: 768px) {
    font-size:16px !important;
  }

`;

const getColor = (rubrique) => {
  const colors = {
    'rubrique1': '#FDBA74',
    'rubrique2': '#FDBA74',
    'rubrique3': '#FDBA74',
    'rubrique4': '#FDBA74',
  };
  return colors[rubrique];
};

