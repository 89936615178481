import React, { useEffect } from 'react';
import './PhotoFrame.css';
import imageMe from '../../webp/photome.png';
import miniatureLogoSociety from '../../webp/logosociety2.webp';

const PhotoFrame = () => {

  const text = ['R', 'O', 'N', 'Y','🔸', 
  'L', 'H', 'O', 'M', 'E', 'R','🔸', 
  'D', 'E', 'V', 'W', 'E', 'B','🔸', 
  'F', 'U', 'L', 'L', 'S', 'T', 'A', 'C', 'K'];

  return (
    <div className='manageContainer'>
      <section className='generalcontainer'>
        <div className="containerh1s">
          {[...Array(6)].map((_, index) => (
            <h1 key={index} className='foranimtext'>
              {text.map((char, i) => (
                <div key={i}>{char}</div>
              ))}
            </h1>
          ))}
          <div className="carrerouge">
            <img src={imageMe} alt="My Photo" />
          </div>
        </div>
      </section>
      <section className='textpresentationContainer'>
        <h1>Bonjour !</h1>
        <div>
            Moi c'est Rony, développeur fullstack freelance. 
        </div>
        <div>
            Je travaille principalement en full remote, à domicile ou dans des open-space mais je peux également me déplacer dans les 
            locaux de votre société pour assurer des missions de régies.
        </div>

        <div>
            Côté front-end j'utilise React et Angular, selon la nature du projet à réaliser. Côté back-end, je privilégie Node JS pour les développements rapides, et Spring Boot pour les projets plus complexes.
        </div>
        <div>
            Conjugée avec mon expérience en process métier, mes softs skills me permettent d'être oragnisé et efficace dans mon travail et de vous fournir la solution la plus adaptée à vos besoins.
        </div>
      </section>
    </div>
  );
};

export default PhotoFrame;
