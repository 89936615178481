import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {isVisible && (
        <button
          className="text-4xl fixed bottom-[30px] right-[40px] z-5000 p-4 backtotopclass text-white rounded-full 
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
          onClick={handleBackToTop}
        >
          <FaArrowUp />
          <span className="sr-only">Retour en haut</span>
        </button>
      )}
    </>
  );
};

export default BackToTop;
