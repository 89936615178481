// import React,{useState, useEffect} from 'react'
// import ReactHtmlParser from 'react-html-parser';
// import { ColorRing } from 'react-loader-spinner'

// const FigureGif = ({videoProjet, texteFigure, miniature}) => {
//   const [mp4File, setmp4File] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect( () => {
//         fetch(videoProjet)
//         .then( (response) => response.blob())
//         .then( (data) => {
//             setmp4File(data);
//             setIsLoading(false);
//         })
//         .catch( (error) => {
//             console.log(error)
//         });
//     }, [])

//   return (
// <>
//       <div className='flex py-5 max-w-[1200px] mx-auto px-5 py-5'
//       style={{flex: '1 1 50%'}}>
//         {
//           !isLoading ? (
//             <div className='mx-auto'>
//               <video src={URL.createObjectURL(mp4File) } type="video/mp4" loop controls muted
//               poster={miniature} />
//               <span className='inline text-[16px] text-white italic py-2 px-2 text-yellow-500'>{
//               ReactHtmlParser(texteFigure)
//               }</span>
//             </div>
//           ):
//           (
//             <div>
//           <ColorRing
//             visible={true}
//             height="120"
//             width="120"
//             ariaLabel="blocks-loading"
//             wrapperStyle={{}}
//             wrapperClass="blocks-wrapper mx-auto"
//             colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
//           />
//           <span className='text-white'>chargement en cours...</span>
//           </div>
//           )
//         }
//       </div>
//     </>
//   )
// }
// export default FigureGif













// import React, { useState, useEffect } from 'react';
// import ReactHtmlParser from 'react-html-parser';
// import { ColorRing } from 'react-loader-spinner';

// const FigureGif = ({ youtubeLink, texteFigure, miniature }) => {
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     setIsLoading(false); // Since YouTube videos load directly, no need for loading state
//   }, []);

//   return (
//     <>
//       <div className='flex py-5 max-w-[1200px] mx-auto px-5 py-5' style={{ flex: '1 1 50%' }}>
//         {!isLoading ? (
//           <div className='mx-auto'>
//             <iframe
//               title='YouTube Video'
//               width='560'
//               height='315'
//               src={youtubeLink}
//               frameBorder='0'
//               allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
//               allowFullScreen
//             ></iframe>
//             <span className='inline text-[16px] text-white italic py-2 px-2 text-yellow-500'>
//               {ReactHtmlParser(texteFigure)}
//             </span>
//           </div>
//         ) : (
//           <div>
//             <ColorRing
//               visible={true}
//               height='120'
//               width='120'
//               ariaLabel='blocks-loading'
//               wrapperStyle={{}}
//               wrapperClass='blocks-wrapper mx-auto'
//               colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
//             />
//             <span className='text-white'>chargement en cours...</span>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default FigureGif;

import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ColorRing } from 'react-loader-spinner';
import YouTube from 'react-youtube';

const FigureGif = ({ youtubeLink, texteFigure, miniature }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoWidth, setVideoWidth] = useState(window.innerWidth <= 768 ? 358 : 560);

  useEffect(() => {
    setIsLoading(false); 

  const handleResize = () => {
    setVideoWidth(window.innerWidth <= 768 ? 358 : 560);

  };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const opts = {
    width: videoWidth,
    height: '315',
    playerVars: {
      // Options pour personnaliser le lecteur YouTube
      modestbranding: 1, // Masquer le logo YouTube
      rel: 0, // Désactiver les suggestions d'autres vidéos
    },
  };

  return (
    <>
      <div className='flex items-start justify-center py-5 max-w-[1200px] mx-auto px-5 py-5' style={{ flex: '1 1 50%' }}>
        {!isLoading ? (
          <div className='flex flex-col items-center justify-center'>
            <YouTube videoId={getYoutubeVideoId(youtubeLink)} opts={opts} />
            <span className='inline text-[16px] text-white italic py-2 px-2 text-yellow-500'>
              {ReactHtmlParser(texteFigure)}
            </span>
          </div>
        ) : (
          <div>
            <ColorRing
              visible={true}
              height='120'
              width='120'
              ariaLabel='blocks-loading'
              wrapperStyle={{}}
              wrapperClass='blocks-wrapper mx-auto'
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
            <span className='text-white'>chargement en cours...</span>
          </div>
        )}
      </div>
    </>
  );
};

// Fonction pour extraire l'ID de la vidéo YouTube à partir du lien
const getYoutubeVideoId = (link) => {
  const videoIdRegex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?/]+)/;
  const match = link.match(videoIdRegex);
  return match && match[1];
};

export default FigureGif;