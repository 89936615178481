import {SiPandas, SiBootstrap, SiPython, SiSqlite, SiApachecouchdb, 
    SiReact, SiTailwindcss, SiJavascript, SiApachecordova, SiMocha, SiAngular, SiNodedotjs, SiExpress, SiAxios, SiSpringboot, SiStripe, SiMysql} from 'react-icons/si'
import {FaLaravel} from 'react-icons/fa'
import {GrMysql} from 'react-icons/gr'
import {AiFillApi} from 'react-icons/ai'


import min_biographe_1 from '../webp/miniature_biographe1.webp'
import min_biographe_2 from '../webp/miniature_biographe2.webp'

import min_zoo_1 from '../webp/miniature_zoo1.webp'
import min_zoo_2 from '../webp/miniature_zoo2.webp'

import min_catcha_1 from '../webp/miniature_catchadata1.webp'
import min_catcha_2 from '../webp/miniature_catchadata2.webp'
import min_catcha_3 from '../webp/miniature_catchadata3.webp'
import min_catcha_4 from '../webp/miniature_catchadata4.webp'
import min_imhotep_1 from '../webp/miniature_imhotep1.webp'
import min_imhotep_2 from '../webp/miniature_imhotep2.webp'
import min_imhotep_3 from '../webp/miniature_imhotep3.webp'
import min_imhotep_4 from '../webp/miniature_imhotep4.webp'

import min_psycho_1 from '../webp/miniature_psycho1.webp'
import min_psycho_2 from '../webp/miniature_psycho2.webp'
import min_psycho_3 from '../webp/miniature_psycho3.webp'

import min_vocabularium_1 from '../webp/miniature_vocabularium1_Z.webp';
import min_vocabularium_2 from '../webp/miniature_vocabularium2_Z.webp';
import min_vocabularium_3 from '../webp/miniature_vocabularium3_Z.webp';
import min_vocabularium_4 from '../webp/miniature_vocabularium4_Z.webp';
import min_vocabularium_5 from '../webp/miniature_vocabularium_5.webp';

import min_tvet1 from '../webp/miniature_tvet1.webp'
import min_tvet2 from '../webp/miniature_tvet2.webp'
import min_tvet3 from '../webp/miniature_tvet3.webp'

export const listeProjet = [
    

    {
        id: '5',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#34d399',
        couleurHaloFort:'#34d399',
        url:'',
        nom: 'Vocabularium',
        colorText:'text-emerald-400',
        hoverButtonColor:'hover:bg-emerald-400',
        concepts:['POO','API','dataframe','CRUD SQL','SQLite','widget','mainloop'],
        description:
        `
        Lire beaucoup et ne pas prendre le temps de chercher la définition d'un mot qui nous est inconnu, quel dommage ! Pour remédier à ce problème, 
        j'ai construit un jeu qui reprend tous les mots capturés au fil de mes lectures. Le but était de trouver un moyen ludique et sympa d'enrichir mon vocabulaire.
        `,
        iconsTech: [
            {
                icon: SiPython,
                color:'text-green-500'
            },
            {
                icon: SiPandas,
                color:'text-orange-500'
            },
            {
                icon: SiSqlite,
                color:'text-white'
            },
            {
                icon:AiFillApi,
                color:'text-blue-300'
            },
        ],
        gifpresentation:
        [
            {
                youtubeLink:'https://youtu.be/6ObE_WkDRyw',
                miniature:min_vocabularium_2,
                legende:'Les différents modes d\'entrainement',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/5OXY86nZjAw',
                miniature:min_vocabularium_1,
                legende:'Entraînement (défilement manuel ou auto)',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },

            {
                youtubeLink:'https://youtu.be/Q7enevSaDI0',
                miniature:min_vocabularium_3,
                legende:'Le coffre de fin et la collection de gemmes',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/pEy3Y8ysOMw',
                miniature:min_vocabularium_4,
                legende:'Connexion à l\'API du Larousse pour la capture des mots',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/bifrSRlVCcY',
                miniature:min_vocabularium_5,
                legende:'Le dojo des mots',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            }

        ]
        ,
        
    },
    {
        id: '4',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#f87171',
        couleurHaloFort:'#f87171',
        url:'',
        nom: 'Zoohoho',
        colorText:'text-red-600',
        hoverButtonColor:'hover:bg-red-600',
        concepts:['POO','MVC','ORM','API','migration','blade','middleware','CSS library',
        'SQL','auth','session'],
        description:
        `
            Un parc zoologique fictif qui m'a permis de me familiariser avec l'architecture MVC
            par la pratique du framework PHP Laravel 8 et son ORM Eloquent.
        `,
        iconsTech: [
            {
                icon: FaLaravel,
                color:'text-red-500'
            },
            {
                icon: GrMysql,
                color:'text-white'
            },
            {
                icon:AiFillApi,
                color:'text-blue-300'
            },
            {
                icon:SiBootstrap,
                color:'text-purple-500'
            }
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/ED4BhGuF0QI',
                miniature:min_zoo_1,
                legende:'fonctionnalités et responsivité de l\'application',
                alttext:'application Laravel 8, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/GdvYJmXzF4k',
                miniature:min_zoo_2,
                legende:'Ajout d\'un commentaire visiteur - sauvegarde dans PHP MyAdmin',
                alttext:'application Laravel 8, démonstration d\'une fonctionnalité'
            }
        ],

    },
    {
        id: '8',
        videoprez:true,
        couleurTexteNeon:'black',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'silver',
        couleurHaloFort:'silver',
        url:'',
        nom: 'Unit test',
        colorText:'text-gray-300',
        hoverButtonColor:'hover:bg-gray-300',
        concepts:['Unit test','Cordova','Mocha','xpath'],
        description:
        `
            Stagiaire chez TVET Academy, une association oeuvrant dans les pays en voie de développement
            dont l'objectif est de faciliter l'accès à la formation. L'application TVET Academy, disponible sur 
            l'Apple store a été développé via la technologie Cordova. JE N'AI PAS CODÉ CETTE APPLICATION, j'ai codé les
            tests unitaires. L'objet de ce stage était de construire une 
            plateforme de test visant à parcourir toutes les fonctionnalités de l'application afin de s'assurer de son 
            bon fonctionnement. Les tests ont été conduit sur différents niveau d'API grâce à un émulateur Android afin 
            de s'assurer que les modèles plus anciens de smartphones puissent aussi accéder correctement aux contenus
            pédagogiques proposés par l'application.
        `,
        iconsTech: [
            {
                icon: SiApachecordova,
                color:'text-blue-300'
            },
            {
                icon: SiJavascript,
                color:'text-red-500'
            },
            {
                icon: SiMocha,
                color:'text-yellow-700'
            }
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/AKvbDN5qXSc',
                miniature:min_tvet1,
                legende:'l\'appli existante sur laquelle les tests unitaires ont été fait',
                alttext:'application Cordova, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/OY-lfwCPOFo',
                miniature:min_tvet2,
                legende:'scripts des différents tests unitaires réalisés',
                alttext:'application Cordova, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/BtG74ZvKseU',
                miniature:min_tvet3,
                legende:'organisation du travail de groupe sur le serveur Discord',
                alttext:'application Cordova, démonstration d\'une fonctionnalité'
            }
        ],
    },
    {
        id: '6',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#fbbf24',
        couleurHaloFort:'#fbbf24',
        url:'',
        nom: 'Imhotep',
        colorText:'text-amber-300',
        hoverButtonColor:'hover:bg-amber-300',
        concepts:['API','JSON','dataframe','threads','kwargs','widgets','cryptography'],
        description: 
        `
            L'objectif de cet exécutable est de faciliter une procédure fastidieuse d'import d'extraction, de nettoyage
            de données, de manipulation via des TCD Excels, et de nombreuses autres tâches répétitives.En un click, on 
            obtient le résultat voulu. Pour faire cela, j'ai utilisé l'API du système d'information de l'ENIT, Muscade, qui
            est développé par l'Association Cocktail (SI utilisé par de nombreux établissements d'enseignement supérieurs).
            Une fois les données récupérées, je les stocke dans un répertoire local, en format JSON. à chaque requête ces fichiers
            JSON se mettent à jour. Je peux alors manipuler la données grâce à la librairie Pandas: fusionner des dataframes
            provenant de sources différentes, agréger les données etc. Avec win32 je construits les tableaux croisés dynamiques
            dont l'utilisateur a besoin. Le fichier produit contient tout ce dont l'utilisateur a besoin (ainsi que le résultat) pour 
            calculer les dotations et amortissement des immobilisations, et peux ainsi se consacrer davantage à l'analyse.
        `,
        iconsTech: [
            {
                icon: SiPython,
                color:'text-green-500'
            },
            {
                icon: SiPandas,
                color:'text-orange-500'
            },
            {
                icon: SiApachecouchdb,
                color:'text-gray-500'
            },
            {
                icon: SiSqlite,
                color:'text-white'
            }
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/jVRtvvgCWBA',
                miniature:min_imhotep_1,
                legende:'La production de reporting automatique et l\'utilisation des threads',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/wxZRMo9_d9g',
                miniature:min_imhotep_2,
                legende:'Modification des données d\'inventaire via l\'API de couchDB',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/T1_Yks6FgT8',
                miniature:min_imhotep_3,
                legende:'Assemblage de données venant de différentes API',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/dq8jO35fKHM',
                miniature:min_imhotep_4,
                legende:'Paramétrages et authentification de l\'utilisateur à l\'API du SI',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            }
        ],

    },
    {
        id: '3',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#93c5fd',
        couleurHaloFort:'#93c5fd',
        url:'https://pcolletbiographe.fr',
        nom: 'Biographe',
        colorText:'text-blue-500',
        hoverButtonColor:'hover:bg-blue-500',
        concepts:['React','GoogleAPI','TailwindCSS','API','Axios','noSQL','NodeJS','Express'],
        description:
        `
            Un biographe Niçois m'a contacté afin que je lui fasse une application web. J'ai choisi le framework JS React. J'ai également développé un control panel qui permet à mon client d'être autonome sur la mise en ligne de ses nouvelles littéraires. Pour ce 
            dernier point, j'ai configuré un sous-domaine lié par un certificat SSL pour la connexion HTTPS. L'API est déployée dans mon serveur VPS et pour la 
            base de données j'ai choisi couchDB (NoSQL) pour sa flexibilité et la rapidité de développement qu'elle permet.
        `,
        iconsTech: [
            {
                icon: SiReact,
                color:'text-cyan-500'
            },
            {
                icon: SiAxios,
                color:'text-blue-300'
            },
            {
                icon:SiTailwindcss,
                color:'text-amber-400'
            }
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/eB17M-cfNdU',
                miniature:min_biographe_1,
                legende:'Format smartphone',
                alttext:'application React JS, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/zNrLg36WP3c',
                miniature:min_biographe_2,
                legende:'responsivité de l\'application',
                alttext:'application React JS, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/ltx8z_WpK50',
                miniature:min_biographe_2,
                legende:'publication d\'une nouvelle via le control panel',
                alttext:'application React JS, démonstration d\'une fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/cB3Im9vAGzk',
                miniature:min_biographe_2,
                legende:'control panel: modifier une nouvelle sur le site web',
                alttext:'application React JS, démonstration d\'une fonctionnalité'
            },
        ],
    },
    {
        id: '7',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#33ffff',
        couleurHaloFort:'#33ffff',
        url:'',
        nom: 'CatchaData',
        colorText:'text-cyan-500',
        hoverButtonColor:'hover:bg-cyan-500',
        concepts:['API','POO','SI','JSON','dataframe','threads','kwargs','widgets',
        'cryptography'],
        description:
        `
            J'ai codé cette application avec Tkinter. J'en ai fait un exécutable qui fourni un certain nombre 
            de requête enrichies, qui proviennent de plusieurs sources: les données du SI via son API Muscade 
            ainsi que des données d'une base de données NoSQL, couchDB. Cette application permet également de 
            suivre des indicateurs d'activité.
        `,
        iconsTech: [
            {
                icon: SiPython,
                color:'text-green-500'
            },
            {
                icon: SiPandas,
                color:'text-orange-500'
            },
            {
                icon: SiApachecouchdb,
                color:'text-gray-500'
            },
            {
                icon: SiSqlite,
                color:'text-white'
            }
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/RefcLZhzU-g',
                miniature:min_catcha_1,
                legende:'Paramètres de l\'appli et BDD SQLite',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/5QpXbhPM3ZY',
                miniature:min_catcha_2,
                legende:'Extractions des données depuis l\'API du SI et système de MAJ des fichiers JSON',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/HiU28Q1FycQ',
                miniature:min_catcha_3,
                legende:'Indicateurs automatiques enregistrées dans une BDD SQLite',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            },
            {
                youtubeLink:'https://youtu.be/EmCvLoDkhGo',
                miniature:min_catcha_4,
                legende:'La distance de Levenshtein, utile pour une saisie mal orthographiée',
                alttext:'application de bureau python, démonstration de fonctionnalité'
            }
        ],
    },
    {
        id:'2',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#7f00ff',
        couleurHaloFort:'#7f00ff',
        url:'https://biaiscognitifs.fun',
        nom: 'Psycho',
        colorText:'text-cyan-500',
        hoverButtonColor:'hover:bg-cyan-500',
        concepts:['VPS','API','NodeJS','Express','CouchDB','Angular','RxJS'],
        description:
        `
            En back-end, j'ai développé une API avec NodeJS et Express que j'ai déployée sur un serveur web Nginx 
            dans un VPS. En front-end, j'ai utilisé Angular 16, qui embarque la librairie RxJS permettant de faire 
            de la programmation réactive via les Observables. Il s'agit d'un projet fullstack qui permet à un utilisateur 
            de créer un compte afin de se familiariser avec la connaissance des biais cognitifs humains dans un contexte ludique. 
            Les pièces d'or gagnées permettent de débloquer les tableaux 3D (paramétrés avec le logiciel Blender et gérés par la librairie ThreeJS).
        `,
        iconsTech: [
            {
                icon: SiAngular,
                color:'text-red-400'
            },
            {
                icon: SiNodedotjs,
                color:'text-orange-500'
            },
            {
                icon: SiExpress,
                color:'text-white'
            },
            {
                icon: SiApachecouchdb,
                color:'text-gray-500'
            },
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/OBHphkcv5zc',
                miniature: min_psycho_1,
                legende:'Création, activation d\'un compte utilisateur et présentation des tableaux 3D',
                alttext:'vidéo démo technique'
            },
            {
                youtubeLink:'https://youtu.be/O2-6-aBFDDs',
                miniature:min_psycho_2,
                legende:'Level-up et inscription au panthéon',
                alttext:'vidéo démo technique'
            },
            {
                youtubeLink:'https://youtu.be/f4SBIRMBN88',
                miniature:min_psycho_3,
                legende:'Système de jeu et entrainement à la reconnaissance des biais cognitifs',
                alttext:'vidéo démo technique'
            },
            {
                youtubeLink:'https://youtu.be/ygpDSglssdw',
                miniature:min_psycho_3,
                legende:'Aperçu général de l\'application',
                alttext:'vidéo démo technique'
            }
        ]
    },
    {
        id:'1',
        videoprez:true,
        couleurTexteNeon:'white',
        couleurHaloFaible0:'white',
        couleurHaloFaible1:'#FCA310',
        couleurHaloFort:'#FCA310',
        url:'https://demo-fedorashop.com',
        nom: 'FedoraShop',
        colorText:'text-cyan-500',
        hoverButtonColor:'hover:bg-cyan-500',
        concepts:['Spring Boot','API REST','Angular','Stripe','MySQL','VPS'],
        description:
        `
FedoraShop est une application e-commerce dédiée à la vente de chapeaux. Bien que fictive, elle fonctionne comme un appartement témoin : chaque étape, de la création d'un compte client jusqu'au paiement, est entièrement opérationnelle. Le front-end est développé avec le framework Angular, tandis que l'API REST est construite avec Spring Boot et hébergée sur un serveur VPS Linux, utilisant le serveur web Tomcat intégré.
        `,
        iconsTech: [
            {
                icon: SiAngular,
                color:'text-red-400'
            },
            {
                icon: SiSpringboot,
                color:'text-orange-500'
            },
            {
                icon: SiMysql,
                color:'text-white'
            },
            {
                icon: SiStripe,
                color:'text-gray-500'
            },
        ],
        gifpresentation:[
            {
                youtubeLink:'https://youtu.be/-vDsIuSldJM',
                legende:'process complet: de l\'activation du compte client au paiement d\'une commande',
                alttext:'Connexion espace utilisateur et présentation du board'
            },
            {
                youtubeLink:'https://youtu.be/F4vKLMGh5SY',
                legende:'Responsivité de l\'application - test avec ResponsivelyApp',
                alttext:'vidéo démo technique'
            },
            {
                youtubeLink:'https://youtu.be/Aby9nhH6xCA',
                legende:'Création et mise en ligne d\'un nouveau produit',
                alttext:'vidéo démo technique'
            },
            {
                youtubeLink:'https://youtu.be/UIsydR9QqYw',
                legende:'Gestion et expédition des commandes depuis le control panel',
                alttext:'vidéo démo technique'
            }
        ]
    }
]