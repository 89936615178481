// Composant parent
import React from 'react';
import ZContainer from './components/ZContainer/ZContainer';
import DetailProjet from './components/Details_projets/Main';
import NotFound from './components/NotFound/NotFound';
import { BrowserRouter, Routes, Route, Switch } from 'react-router-dom';
import ArticleBook from './components/ArticleBook/ArticleBook';

function App() {
    return (
      <>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<ZContainer/>} />
                <Route path='/projet/:id' element={<DetailProjet/>} />
                <Route path='/guideforgeek/:id' element={<ArticleBook/>} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
      </>
    );
  }
  
export default App;
    