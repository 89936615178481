import React from 'react'
import styled from 'styled-components';




const ButtonFlash = ({texte, idprojet, handleClick}) => {

  const identifiantCouleur = [
    {
      idColor:'1',
      color: "orange",
      hexaColor: "#FFA500",
      bkgroung: "background: rgba(255, 165, 0, 0.1);",
      bkgbefore: "background: rgba(255, 165, 0, 0.1);",
      boxsh: "box-shadow: inset 0 0 10px rgba(255, 165, 0, 0.2);",
     boxshHover: "box-shadow: inset 0 0 10px rgba(255, 165, 0, 0.4);"
    },
    {
      idColor:'4',
      color:'red',
      hexaColor:'#FF4136',
      bkgroung: 'background: linear-gradient(to right, rgba(255, 65, 54, 0.1) 1%, transparent 40%, transparent 60%, rgba(255, 65, 54, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(255, 65, 54, 0.1) 40%, rgba(255, 65, 54, 0.1) 60%, transparent 100%);',
      boxsh:'box-shadow: inset 0 0 10px rgba(255, 65, 54, 0.4), 0 0 9px 3px rgba(255, 65, 54, 0.1);',
      boxshHover:'box-shadow: inset 0 0 10px rgba(255, 65, 54, 0.6), 0 0 9px 3px rgba(255, 65, 54, 0.2);'
    },
    {
      idColor:'8',
      color:'white',
      hexaColor:'#F8F8F8',
      bkgroung: 'linear-gradient(to right, rgba(248, 248, 248, 0.1) 1%, transparent 40%, transparent 60%, rgba(248, 248, 248, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(248, 248, 248, 0.1) 40%, rgba(248, 248, 248, 0.1) 60%, transparent 100%);',
      boxsh:'box-shadow: inset 0 0 10px rgba(248, 248, 248, 0.4), 0 0 9px 3px rgba(248, 248, 248, 0.1);',
      boxshHover:'box-shadow: inset 0 0 10px rgba(248, 248, 248, 0.6), 0 0 9px 3px rgba(248, 248, 248, 0.2);'
    },
    {
      idColor:'6',
      color:'yellow',
      hexaColor:'#FFA500',
      bkgroung: 'background: linear-gradient(to right, rgba(254, 190, 40, 0.1) 1%, transparent 40%, transparent 60%, rgba(255, 106, 0, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(254, 190, 40, 0.1) 40%, rgba(255, 106, 0, 0.1) 60%, transparent 100%);',
      boxsh:'box-shadow: inset 0 0 10px rgba(254, 190, 40, 0.4), 0 0 9px 3px rgba(255, 106, 0, 0.1);',
      boxshHover:'box-shadow: inset 0 0 10px rgba(254, 190, 40, 0.6), 0 0 9px 3px rgba(255, 106, 0, 0.2);'
    },
    {
      idColor:'3',
      color:'blue',
      hexaColor:'#1B83FD',
      bkgroung: 'background: linear-gradient(to right, rgba(27, 131, 253, 0.1) 1%, transparent 40%, transparent 60%, rgba(27, 131, 253, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(27, 131, 253, 0.1) 40%, rgba(27, 131, 253, 0.1) 60%, transparent 100%);',
      boxsh:'box-shadow: inset 0 0 10px rgba(27, 131, 253, 0.4), 0 0 9px 3px rgba(27, 131, 253, 0.1);',
      boxshHover:'box-shadow: inset 0 0 10px rgba(27, 131, 253, 0.6), 0 0 9px 3px rgba(27, 131, 253, 0.2);'
    },
    {
      idColor: '7',
      color: 'cyan',
      hexaColor: '#00FFFF',
      bkgroung: 'background: linear-gradient(to right, rgba(0, 255, 255, 0.1) 1%, transparent 40%, transparent 60%, rgba(0, 255, 255, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(0, 255, 255, 0.1) 40%, rgba(0, 255, 255, 0.1) 60%, transparent 100%);',
      boxsh: 'box-shadow: inset 0 0 10px rgba(0, 255, 255, 0.4), 0 0 9px 3px rgba(0, 255, 255, 0.1);',
      boxshHover: 'box-shadow: inset 0 0 10px rgba(0, 255, 255, 0.6), 0 0 9px 3px rgba(0, 255, 255, 0.2);'
      
    },
    {
      idColor: '2',
      color: 'violet',
      hexaColor: '#8A2BE2',
      bkgroung: 'background: linear-gradient(to right, rgba(138, 43, 226, 0.1) 1%, transparent 40%, transparent 60%, rgba(138, 43, 226, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(138, 43, 226, 0.1) 40%, rgba(138, 43, 226, 0.1) 60%, transparent 100%);',
      boxsh: 'box-shadow: inset 0 0 10px rgba(138, 43, 226, 0.4), 0 0 9px 3px rgba(138, 43, 226, 0.1);',
      boxshHover: 'box-shadow: inset 0 0 10px rgba(138, 43, 226, 0.6), 0 0 9px 3px rgba(138, 43, 226, 0.2);'
    },
    {
      idColor: '5',
      color:'green',
      hexaColor:'#1BFD9C',
      bkgroung: 'background: linear-gradient(to right, rgba(27, 253, 156, 0.1) 1%, transparent 40%, transparent 60%, rgba(27, 253, 156, 0.1) 100%);',
      bkgbefore: 'background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);',
      boxsh:'box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.4), 0 0 9px 3px rgba(27, 253, 156, 0.1);',
      boxshHover:'box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.6), 0 0 9px 3px rgba(27, 253, 156, 0.2);'
    },
  ]

  const idcouleur = identifiantCouleur.find(x => x.idColor==idprojet)

  const FlashButton = styled.button`
    --nameColor: ${idcouleur.hexaColor};
    font-size: 15px;
    padding: 0.7em 2.7em;
    letter-spacing: 0.06em;
    position: relative;
    font-family: inherit;
    border-radius: 0.6em;
    overflow: hidden;
    transition: all 0.3s;
    line-height: 1.4em;
    border: 2px solid var(--nameColor);
    ${idcouleur.bkgroung}
    color: var(--nameColor);
    ${idcouleur.boxsh}
    &:hover {
      color: ${idcouleur.hexaColor};
      ${idcouleur.boxshHover}
    }
    &:before {
      content: "";
      position: absolute;
      left: -4em;
      width: 4em;
      height: 100%;
      top: 0;
      transition: transform 0.4s ease-in-out;
      ${idcouleur.bkgbefore}
    }
    &:hover:before {
      transform: translateX(15em);
    }
  `;
  
  return (
    <FlashButton onClick={handleClick}>{texte}</FlashButton>
  )
}

export default ButtonFlash