import React, { useState, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactGA4 from 'react-ga4';

const CookieConsentBanner = () => {
    const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

    const handleAccept = () => {
        setAnalyticsEnabled(true);
        console.log("Cookies acceptés !");
    };

    const handleDecline = () => {
        setAnalyticsEnabled(false);
        console.log("Cookies refusés !");
    };

    useEffect(() => {
        if (analyticsEnabled) {
            ReactGA4.initialize('G-44KYB5V78C');
        }
    }, [analyticsEnabled]);

    return (
        <CookieConsent
            location="bottom"
            buttonText="Accepter"
            declineButtonText="Refuser"
            cookieName="myCookieConsent"
            enableDeclineButton
            onAccept={handleAccept}
            onDecline={handleDecline}
            style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', zIndex:55555555}}
            contentStyle={{ padding: '20px' }}
            buttonStyle={{ background: 'green', color: 'white', fontSize: '16px', borderRadius: '4px' }}
            declineButtonStyle={{ background: 'red', color: 'white', fontSize: '16px', borderRadius: '4px' }}
        >
            Ce site utilise des cookies pour améliorer votre expérience. En continuant à naviguer sur ce site, 
            vous acceptez notre utilisation des cookies.
        </CookieConsent>
    );
};

export default CookieConsentBanner;
