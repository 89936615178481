import React, { useState } from 'react';

const SwitchButtonMailDraw = ( {mode, toggleMode }) => {
    return (
        <section className='sectionBoutonSwitchMailDraw'>
          <input id="checkbox_toggle" type="checkbox" className="check" onChange={toggleMode} checked={mode === 'draw'}/>
          <div className="checkbox">
            <label className="slide" htmlFor="checkbox_toggle">
              <label className="toggle" htmlFor="checkbox_toggle"></label>
              <label className="text" htmlFor="checkbox_toggle">Mail</label>
              <label className="text" htmlFor="checkbox_toggle">Dessin</label>
            </label>
          </div>
        </section>
    );
}
export default SwitchButtonMailDraw;