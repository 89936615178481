import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser';
import { v4 as uuidv4 } from 'uuid';

const BoutonAccordeon = ({numeroLabel, question, reponse, 
  couleur, couleurFondQuestion, couleurFondReponse, couleurTexteReponse}) => {
    const [currentReponse, setCurrentReponse] = useState('');
    useEffect(() => {
        setCurrentReponse(reponse);

    }, [reponse])

    const TarteAuxPommes = styled.label`
    display: block;
    padding: 0.8em;
    width: 100%;
    border-bottom: 0.1em solid ${couleur};
    background: transparent;
    color: ${couleur};
    font-family: 'Gabriela';
    font-size: 2em;
    line-height: 1em;
    cursor: pointer;
    &:hover{
      background: ${couleur};
      color: black;
    }
    `

    
    const Clafoutis = styled.input`
    display: none;
    &:checked + .collapsible {
      overflow: auto;
      height: auto;
    }
    `
    const FarBreton = styled.div`
    display: block;
    padding: 0 1em;
    width: 100%;
    background: ${couleurFondReponse};
    overflow-y:scroll;
    height: 0;
    transition:height 1s ease;

    & p {
      margin:0
    }
    
    
    `

    return (
        <>
    <div>
        <TarteAuxPommes className='text-[20px] sm:text-[20px]' htmlFor={`panel_${numeroLabel}`}>{question}</TarteAuxPommes>
        <Clafoutis type="radio" name="panel" id={`panel_${numeroLabel}`}></Clafoutis>
        <FarBreton className="collapsible">
            {
            <p className={`font-poppins ${couleurTexteReponse} text-[16px] text-justify py-5`} key={uuidv4()}>
              {ReactHtmlParser(currentReponse)}
            </p>
            }

        </FarBreton>
    </div>
  </>

  )
}

export default BoutonAccordeon