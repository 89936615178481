import React from 'react';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import image404 from '../../webp/error404.webp';

const NotFound= () => {
 


  return (
    <footer className=''>
        <Menu/>


    <div className='bg-green-100 pageErreur'>
        <div className=''/>

      <div className='text-black text-center text-black text-xl
      xl:max-w-[80%] mx-auto'>
        
        <div className='flex justify-center items-center text-4xl font-roboto'>
        ⚡ La page demandée est introuvable ! ⚡
        </div>
        <div className='imageQCQ'>
                <img src={image404} alt="logo en forme de labyrinthe"/>
            </div>
        <div className='second_line_footer'>
            <p className='text-[14px] text-center secondlignp'>Mentions Légales</p>
        </div>
      </div>
      
    </div>
    <Footer/>

    </footer>
  )
}

export default NotFound