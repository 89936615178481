import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import FalshBtn2 from '../FalshBtn2';
import DrawingCanvas from '../Dessiner/Dessiner';



const Contact = ({ toggleMode }) => {
  const form = useRef();
  const x1 = process.env.REACT_APP_BANANE
  const x2 = process.env.REACT_APP_ORANGE
  const x3 = process.env.REACT_APP_KIWI


  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    const name = form.current.user_name.value;
    const email = form.current.user_email.value;
    const message = form.current.message.value;
    const reason = form.current.reason.value;
    const society = form.current.society.value;
    if (!name || !email || !message || !reason || !society) {
      setErrorMessage('Merci de remplir tous les champs !');
      setSuccessMessage(null);

      setTimeout(() => {
        setErrorMessage(null);
      }, 2000); // Disparaît après 3 secondes
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/ ;
    if (!emailPattern.test(email)) {
      setErrorMessage('Veuillez saisir une adresse e-mail valide');
      setSuccessMessage(null);

      setTimeout(() => {
        setErrorMessage(null);
      }, 2000); // Disparaît après 3 secondes

      return;
    }

    emailjs.sendForm(x1, x2, form.current, x3)
      .then(() => {
        setSuccessMessage('Votre message a bien été envoyé !');
        setErrorMessage(null);
        setTimeout(() => {
          setSuccessMessage(null);
          // Vider les champs du formulaire
          form.current.user_name.value = '';
          form.current.user_email.value = '';
          form.current.message.value = '';
          form.current.reason.value = '';
          form.current.society.value = '';
        }, 
        2000); // Disparaît après 3 secondes
      })
      .catch(() => {
        setErrorMessage('Une erreur est survenue lors de l\'envoi du message');
        setSuccessMessage(null);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000); // Disparaît après 3 secondes
      });
  };


  return (
    <section id='sendMailSection'>

    <div 
    className='xl:max-w-[80%] mx-auto'>

   
    <section id="contact" >
    <div className='w-full mx-auto'>


    <form className="mx-auto mt-10 w-[90%]" ref={form} onSubmit={sendEmail}>

      {
        successMessage && (
          <div className="text-[14px] sm:text-[18px] text-green-300 text-center">{successMessage}</div>
        )
      }
      {
        errorMessage && (
          <div className="text-[14px] sm:text-[18px] text-white text-center">{errorMessage}</div>
        )
        }
      <div className="mb-5">
        <label
          htmlFor="reason"
          className="block text-[14px] sm:text-[18px] font-bold mb-2 font-gabriela titremail"
        >
          Objet
        </label>
        <select
          id="reason"
          name="reason"
          className="text-gray-700 text-[14px] sm:text-[18px] block appearance-none w-full border border-gray-400 py-2 px-3 rounded-lg leading-tight focus:outline-none focus:border-gray-500"
        >
          <option value="">--Choisir une option--</option>
          <option value="En réponse à ta candidature">En réponse à ta candidature</option>
          <option value="Mission à te proposer">Mission à te proposer</option>
          <option value="Autre chose">Autre chose</option>
        </select>
      </div>
      <div className="mb-5">
        <label
          htmlFor="name"
          className="block  text-[14px] sm:text-[18px] font-bold mb-2 font-gabriela titremail"
        >
          Nom et prénom
        </label>
        <input
          type="text"
          id="name"
          name="user_name"
          className="text-[14px] sm:text-[18px] font-gabriela shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-5">
        <label
          htmlFor="name"
          className="block  text-[14px] sm:text-[18px] font-bold mb-2 font-gabriela titremail"
        >
          Société
        </label>
        <input
          type="text"
          id="society"
          name="user_society"
          className="text-[14px] sm:text-[18px] font-gabriela shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-5">
        <label
          htmlFor="email"
          className="block  text-[14px] sm:text-[18px] font-bold mb-2 font-gabriela titremail"
        >
          Mail
        </label>
        <input
          type="email"
          id="email"
          name="user_email"
          className="text-[14px] sm:text-[18px] shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-5">
        <label
          htmlFor="message"
          className="block  text-[14px] sm:text-[18px] font-bold mb-2 font-gabriela titremail"
        >
          Message
        </label>
        <textarea
          id="message"
          name="message"
          className="text-[14px] sm:text-[18px] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight  "
          rows="5"
        ></textarea>
      </div>
      <div className="flex justify-center py-8">

        <FalshBtn2 color={"#C5B358"} texte={"envoyer"} handleClick={sendEmail}/>
    
      </div>
    </form>
    </div>
    </section>
    </div>

    </section>
  )
}

export default Contact