import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../Menu/Menu';
import '../../../src/articlecontent.css';


const ArticleNotAvailable = () => {
    const [bookcontent, setBookContent] = useState([]);
    const { id } = useParams();




    return (
        <section className='notAvailable'>
            <div class='pt-[95px]' />
            <div className='container_notavailable'>
                <h1 className='text-[18px] sm:text-[22px] text-yellow-200'>
                    Ce guide de survie est en cours de conception, il sera très bientôt 
                    disponible !
                </h1>
                <img src='../images/article_notavailable.png' />
                {/* ../images/article_notavailable.jpg */}
            </div>
        </section>
    )
}

export default ArticleNotAvailable;
