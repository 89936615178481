import React from 'react'
import styled from 'styled-components'

// Définissez les composants Styled Components en dehors de la fonction de composant
const Container = styled.div`
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Btn = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  
  & a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none;
    font-family:"Poppins";
    font-size:18px;

    overflow: hidden;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
  }

  &:hover a {
    letter-spacing: 3px;
    color: white;
  }

  & a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
  }

  &:hover a::before {
    transform: skewX(45deg) translate(200px);
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 30px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  &:hover::before {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 30px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
  }

  &:hover::after {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
  }

  &:nth-child(1)::before,
  &:nth-child(1)::after {
    background: ${props => props.color};
    box-shadow: 0 0 5px ${props => props.color}, 0 0 15px ${props => props.color}, 0 0 30px ${props => props.color},
    0 0 60px ${props => props.color}; 
`;

const FalshBtn2 = ({color, texte, handleClick}) => {
  return (
    <Container>
      {/* Utilisez le composant Btn avec la prop 'color' */}
      <Btn color={color}><a onClick={handleClick}>{texte}</a></Btn>
    </Container>
  )
}

export default FalshBtn2
