import React from 'react';
import '../../cards_style.css';
import Menu from '../Menu/Menu';
import Home from '../Home/Home';
import PrestaBlock from '../PrestaBlock/PrestaBlock';
import Presentation from '../Presentation/Presentation';
import Portfolio from '../Portfolio_layout/Portfolio';
import FaqCarousel from '../FaqCarousel/FaqCarousel';
import Footer from '../Footer/Footer';
import BackToTop from '../BackToTop';
import CookieConsentBanner from '../CookieConsentBanner/cookieConsentBanner';
import ToggleContactCanvas from '../ToggleContactCanvas/ToggleContactCanvas';
import Books from '../Book/Book';
import OrbitalAnimation from '../OrbitalAnimation/OrbitalAnimation';
import PhotoFrame from '../PhotoFrame/PhotoFrame';
const ZContainer = () => {
    return(
        <>
            <Menu />
            <OrbitalAnimation />

            <div style={{ position: 'relative', zIndex: 1 }} >
        <section className="home" id="home">
            <Home/>



            <PrestaBlock/>
            <Presentation/>
            
            
        </section>

        <section className='megacontainerPh'>
        <PhotoFrame/>
        <div class="cut2"></div>
        <div class="cut3"></div>
        </section>

        <section className='' id='portfolio'>
        
            <Portfolio/>
        </section>


        <section className='faq' id='faq'>
            <FaqCarousel />
        </section>
        <section>
            <Books/>
        </section>

        <section id="contact">
            <ToggleContactCanvas/>
        </section>




        <section className='footerGen'>
            <Footer/>
        </section>
        <BackToTop/>
        <CookieConsentBanner/>
        </div>

    </>
    )
}
export default ZContainer;